import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import { collectionsAndInterfaces } from '../../constants'
import { Permit } from '..'
import { table } from '../../styles'
import withStyles from '@material-ui/core/styles/withStyles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'

const CollectionTableHead = props => {
  const createSortHandler = property => event => {
    props.onRequestSort(event, property)
  }

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    type,
    classes,
    selectable,
    removable,
  } = props

  const { rows } = props.rows
    ? props
    : find(collectionsAndInterfaces, { name: type })

  return (
    <TableHead>
      <TableRow>
        {selectable !== false && type !== 'project' && (
          <Permit permissions={774}>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          </Permit>
        )}

        {rows.map((row, i) => {
          return (
            <TableCell
              key={`${row.id}-${i}`}
              align={row.numeric ? 'right' : 'left'}
              className={classes.tableCell}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          )
        }, this)}

        {removable && <TableCell className={classes.tableCell} />}
      </TableRow>
    </TableHead>
  )
}

CollectionTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  // orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default withStyles(table)(CollectionTableHead)
