import React from 'react'
import Grid from '@material-ui/core/Grid'
import { SearchMapping } from '..'

const SearchAdvanced = props => {
  if (!props.advanced) return null
  return (
    <Grid container style={{ paddingRight: 24 }}>
      <SearchMapping
        terms={props.terms}
        mapping={props.mapping}
        selectedFields={props.selectedFields}
        toggleField={props.toggleField}
        classes={props.classes}
        processing={props.processing}
        handleOperatorChange={props.handleOperatorChange}
        getOperators={props.getOperators}
        handleChange={props.handleChange}
      />
    </Grid>
  )
}

export default SearchAdvanced
