export const SEARCH_RESULTS_UPDATE = 'search/SEARCH_RESULTS_UPDATE'
export const SEARCH_RESULTS_CLEAR = 'search/SEARCH_RESULTS_CLEAR'

const initialState = {
  results: [],
  total: 0,
}

export const update = ({ results }) => dispatch => {
  const { data, total } = results
  dispatch({
    type: SEARCH_RESULTS_UPDATE,
    payload: { results: data, total },
  })
}

export const clear = () => dispatch => {
  dispatch({
    type: SEARCH_RESULTS_CLEAR,
    payload: {},
  })
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_RESULTS_UPDATE:
      return {
        ...action.payload,
      }
    case SEARCH_RESULTS_CLEAR:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
