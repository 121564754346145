import * as R from 'ramda'
import head from 'lodash/head'
import findIndex from 'lodash/findIndex'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import uniq from 'lodash/uniq'
import isString from 'lodash/isString'
import _pluralize from 'pluralize'
import download from 'downloadjs'

export const titleCase = str => startCase(toLower(str))

export const formatCurrency = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
  }
}

export const formatCost = cost => `€ ${formatCurrency(cost / 100, 2, ',', '.')}`

export const stripBlacklistedSyntax = html => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const elems = Array.prototype.slice.call(doc.querySelectorAll('*'), 0)
  for (let i = 0; i < elems.length; i++) {
    elems[i].removeAttribute('style')
  }

  return doc.body.innerHTML
}

// 2018-03-13T00:00:00.000Z -> 2018/03/13
export const datetimeToInput = str => {
  if (typeof str !== 'string' && str !== null) {
    console.warn(
      'datetimeToInput expects typeof String',
      typeof str,
      'provided',
    )
    return ''
  }

  console.log('str', str)

  const date = new Date(str)
  const y = date.getFullYear()

  console.log('y', y)

  const m = String(date.getMonth() + 1).padStart(2, '0')

  console.log('m', m)

  const d = String(date.getDate()).padStart(2, '0')

  console.log('d', d)

  return `${y}/${m}/${d}`
}

// 2018-03-13 -> 2018-03-13T00:00:00.000Z
// TODO: necessary for Rails?
export const inputToDatetime = str => {
  if (typeof str !== 'string' && str !== null) {
    console.warn(
      'inputToDatetime expects typeof String',
      typeof str,
      'provided',
    )
    return ''
  }

  return `${str}T00:00:000Z`
}

export const datetimeToHuman = str => {
  if (typeof str !== 'string' && str !== null) {
    console.warn(
      'datetimeToHuman expects typeof String',
      typeof str,
      'provided',
    )
    return ''
  }
  const date = new Date(str)
  const y = date.getFullYear()
  const m = String(date.getMonth() + 1).padStart(2, '0')
  const d = String(date.getDate()).padStart(2, '0')

  return `${d}.${m}.${y}`
}

export const datetimeToYear = str => {
  if (typeof str !== 'string' && str !== null) {
    console.warn('datetimeToYear expects typeof String', typeof str, 'provided')
    return ''
  }
  const date = new Date(str)
  const y = date.getFullYear()

  return y
}

export const defaultDateInputValue = () =>
  datetimeToInput(new Date().toISOString())

// `type` based on Rails data types
export const castType = (value, type) => {
  if (typeof value === 'undefined') {
    console.warn('validateType expects a defined value parameter')
    return value
  }

  switch (type) {
    case 'string':
    case 'enum':
      return String(value)
    case 'integer':
    case 'boolean': // stored in Rails as int
      return Number(value)

    default:
      return value
  }
}

export const getCollectionType = (props = window) =>
  head(props.location.pathname.split('/').filter(Boolean))

export const parameterize = str =>
  toLower(str)
    .replace(/[^a-z0-9]/g, '_')
    .replace(/_{2,}/g, '_')
    .replace(/(?:^_|_$)/g, '')

export const unparameterize = str => titleCase(str).replace(/_/g, ' ')

// singular form
export const getCollectionNameFromMimeType = type => {
  if (/^image/.test(type)) return 'image'
  if (/^video/.test(type)) return 'video'
  if (/^audio/.test(type)) return 'audio'
  if (/pdf$/.test(type)) return 'pdf'
  return 'document'
}

_pluralize.addIrregularRule('audio', 'audios')
export const pluralize = _pluralize

// URL stuff
//

// Ensure a string is decoded for use in URL
export const ensureDecodedURI = str => {
  let str_ = String(str)
  if (!str_) return str_
  while (str_ !== window.decodeURI(str_)) str_ = window.decodeURI(str_)
  return str_
}

export const ensureDecodedURIComponent = str => {
  let str_ = String(str)
  if (!str_) return str_
  while (str_ !== window.decodeURIComponent(str_))
    str_ = window.decodeURIComponent(str_)
  return str_
}

// Create URL safe string
export const safeEncodeURIComponent = str =>
  window.encodeURIComponent(ensureDecodedURI(str))

// Create partial state object from a query string
// @params query    string (query string)
// @return Object<q: string, filters: Map<key:string, value:string[]>>
export const createStatePartialFromQueryString = query => {
  const params = new window.URLSearchParams(query)
  const result = { q: '', filters: new Map() }

  result.q = params.get('q')
  params.delete('q')
  params.forEach((val, key) => {
    const key_ = key.slice(0, -2) // trim '[]'
    if (!result.filters.has(key_)) return result.filters.set(key_, [val])
    result.filters.set(key_, [...result.filters.get(key_), val])
  })

  return result
}

// Concat q with filter parameters
// @params str:     string (query string)
// @params params:  Map<key:string, value:string[]>
export const appendFiltersToQueryString = (str, params) => {
  let params_ = ''
  params.forEach(
    (value, key) =>
      (params_ += value.reduce(
        (acc, curr) => (acc += `&${key}[]=${curr}`),
        '',
      )),
  )

  return `${str}${params_}`
}

// Create query string from state params
// @params state:   Object<q: string, filters: Map<key:string, value:string[]>>
// @return string
export const getQueryStringFromStatePartial = state => {
  const state_ = { ...state }
  let query = `?q=${state_.q}`
  delete state_.q

  state_.filters.forEach(
    (val, key) =>
      (query += val.reduce((acc, curr) => (acc += `&${key}[]=${curr}`), '')),
  )

  return query
}

export const getDefaultValue = inputType => {
  switch (inputType) {
    case 'string':
    case 'text':
    case 'date':
      return ''
    case 'number':
      return 0
    default:
      return ''
  }
}

export const getInputType = fieldType => {
  switch (fieldType) {
    case 'string':
    case 'decimal': // TODO text?
    case 'float': // TODO text?
      return 'text'
    case 'text':
      return 'textarea'
    case 'date': // eh?
    case 'datetime':
      return 'date'
    case 'number': // eh?
    case 'integer':
      return 'number'
    case 'enum':
      return 'select'
    case 'boolean':
      return 'checkbox'

    default:
      return fieldType
  }
}

export const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export const getSorting = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

// https://stackoverflow.com/questions/35538351/ramda-js-lens-for-deeply-nested-objects-with-nested-arrays-of-objects
export const lensMatching = pred => toF => entities => {
  const index = R.findIndex(pred, entities)
  return R.map(
    entity => R.update(index, entity, entities),
    toF(entities[index]),
  )
}

export const lensById = R.compose(lensMatching, R.propEq('id'))

export const isNewItem = props => {
  if (props && props.match && props.match.params) {
    return props.match.params.id === 'new'
  }
  const url = new window.URL(window.location)
  const pathname = url.pathname.replace(/\/+$/, '')
  return /new$/.test(pathname)
}

export const createMultipleSelectOptions = (props, collection) =>
  props[collection].map(value => {
    const inputType = 'text' // TODO: should allow for inputType 'project', 'category', etc
    const inputData = props.createInputData({
      id: value.id,
      name: collection,
      label: collection === 'categories' ? titleCase(value.name) : value.title,
      value,
      checked: findIndex(props.item[collection], { id: value.id }) > -1,
      inputType,
      collection: collection,
      readonly: false,
      options: [],
    })

    return inputData
  })

export const getTitle = item => item.title || item.display_name || ''

export const getDimensions = item => item.dimensions

export const getCardMediaSource = item =>
  item.urls.small || item.urls.preview || item.urls.source

export const getComponentNameFromMimeType = type =>
  /(?:audio|video)/.test(type) ? type : 'img'

export const getCardComponent = item =>
  getComponentNameFromMimeType(getCollectionNameFromMimeType(item.mime_type))

export const extractErrorMessages = data => {
  const message = []
  if (isString(data)) {
    // 'data' is html response, return generic error
    message.push('Request failed')
  } else {
    Object.entries(data).forEach(([key, values]) => {
      message.push(
        uniq(values).reduce(
          (acc, curr) => acc.concat(`${unparameterize(key)} ${curr}`),
          [],
        ),
      )
    })
  }
  return message
}

export const handleDownload = data => {
  const { url, file_name, mime_type } = data
  const req = new XMLHttpRequest()

  req.open('GET', url, true)
  req.responseType = 'blob'
  req.onload = () => download(req.response, file_name, mime_type)
  req.send()
}
