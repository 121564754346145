import React from 'react'
import { Form } from '../Form'
import fieldsWrapper from '../fieldsWrapper'

class CollectionItemFields extends React.Component {
  render() {
    return (
      <Form
        type={this.props.type}
        item={this.props.item}
        schema={this.props.schema}
        createInput={this.props.createInput}
        createInputData={this.props.createInputData}
        categories={this.props.categories}
        tags={this.props.tags}
        projects={this.props.projects}
        exhibitions={this.props.exhibitions}
        impressions={this.props.impressions}
        previews={this.props.previews}
        handleChange={this.props.handleChange}
        handleNestedItemChange={this.props.handleNestedItemChange}
        handleSubmit={this.props.handleSubmit}
        handleDelete={this.props.handleDelete}
        handleDownload={this.props.handleDownload}
        handleDeleteItem={this.props.handleDeleteItem}
        handleToggleVisibility={this.props.handleToggleVisibility}
        handleDeleteChip={this.props.handleDeleteChip}
        handleAddChip={this.props.handleAddChip}
        handleRemoveItem={this.props.handleRemoveItem}
        handleFileChange={this.props.handleFileChange}
        handleCheckboxChange={this.props.handleCheckboxChange}
        handleThumbnailChange={this.props.handleThumbnailChange}
        handleThumbnailDelete={this.props.handleThumbnailDelete}
        createFormFields={this.props.createFormFields}
        handleExpandClick={this.handleExpandClick}
        handleCopy={this.props.handleCopy}
        moveItems={this.props.moveItems}
        handleOpen={this.props.handleOpen}
        sidebarTop={this.props.sidebarTop}
        sidebarRight={this.props.sidebarRight}
        sidebarBottom={this.props.sidebarBottom}
        sidebarLeft={this.props.sidebarLeft}
        sidebarTabs={this.props.sidebarTabs}
      />
    )
  }
}

export default fieldsWrapper(CollectionItemFields)
