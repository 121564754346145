import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Grid from '@material-ui/core/Grid'
import { load, clear, verify } from '../reducers/auth'
import { login, validate } from '../reducers/api'
import { Login } from '.'

class Authenticated extends React.Component {
  componentDidMount() {
    this.load()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { key } = nextProps.location
    if (key !== this.props.location.key) {
      this.props.verify()
    }
  }

  load = () => {
    this.props
      .load()
      .then(data => this.props.validate(data))
      .then(resp => !resp.data.id && this.redirect())
      .then(data => this.props.verify(data))
      .catch((/* err */) => this.redirect()) // TODO: handle error?
  }

  redirect = () => {
    this.props.clear().then(() => {
      if (this.props.history.location.pathname !== '/') {
        this.props.history.replace('/', {})
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.props.auth.email && this.props.auth.jwt ? (
          this.props.children
        ) : (
          <Grid container>
            <Grid item sm={12}>
              <Login />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      verify,
      load,
      clear,
      login,
      validate,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Authenticated))
