import React from 'react'
import find from 'lodash/find'
import {
  titleCase,
  datetimeToInput,
  getDefaultValue,
  getInputType,
} from '../util'
import { form } from '../styles'

import withStyles from '@material-ui/core/styles/withStyles'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'

const fieldsWrapper = WrappedComponent => {
  class FieldsHOC extends React.Component {
    state = { expanded: false }

    createFormFields = ({ item }) => {
      const { schema, type } = this.props
      const inputs = []

      Object.entries(item).forEach(([name, value]) => {
        if (typeof schema[type][name] === 'undefined') {
          console.warn('Schema does not support', name)
          return
        }
        const { label, fieldType } = schema[type][name]
        const inputType = getInputType(fieldType)
        const data = this.createInputData({
          name,
          value,
          label,
          inputType,
          ...schema[type][name],
        })

        inputs.push(this.createInput(inputType, data))
      })

      return <div>{inputs}</div>
    }

    createOptions = arr =>
      arr && arr.length
        ? arr.reduce(
            (acc, { label, value }) => acc.concat({ label, value }),
            [],
          )
        : []

    getEnumValue = (label, options) => {
      const option = find(options, { value: label })
      if (!option) {
        // console.warn('Cannot find option for', label)
        return ''
      }
      return option.value
    }

    createValue = ({ value, inputType, options }) => {
      switch (inputType) {
        case 'date':
          return datetimeToInput(value)
        case 'select':
          return this.getEnumValue(value, options)
        default:
          return value || getDefaultValue(inputType)
      }
    }

    createInputData = props => ({
      id: props.id || props.name,
      name: props.name,
      inputType: props.inputType,
      value: this.createValue(props),
      label: props.label || titleCase(props.name),
      readonly: props.readonly,
      required: props.required,
      hidden: props.hidden,
      checked: props.checked,
      collection: props.collection,
      options: this.createOptions(props.options),
    })

    handleExpandClick = ({ id }) => {
      this.setState(state => ({ expanded: state.expanded === id ? null : id }))
    }

    createCardCollapse = item => (
      <Collapse
        in={this.state.expanded === item.id}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          {this.createFormFields({ schema: this.props.schema, item })}
        </CardContent>
      </Collapse>
    )

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getEnumValue={this.getEnumValue}
          createInput={this.createInput}
          createInputData={this.createInputData}
          createValue={this.createValue}
          createCardCollapse={this.createCardCollapse}
          createOptions={this.createOptions}
          createFormFields={this.createFormFields}
          createMediaField={this.createMediaField}
          handleExpandClick={this.handleExpandClick}
        />
      )
    }
  }

  return withStyles(form)(FieldsHOC)
}

export default fieldsWrapper
