import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/App'
import CssBaseline from '@material-ui/core/CssBaseline'
import { darken } from '@material-ui/core/styles/colorManipulator'
import green from '@material-ui/core/colors/green'
import yellow from '@material-ui/core/colors/amber'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React)
// }

const theme = createMuiTheme({
  shadows: Array(25).fill('none'),
  typography: {
    useNextVariants: true,
    fontFamily:
      '-apple-system,system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: 12,
  },
  palette: {
    primary: {
      light: '#fff',
      main: '#0096EF',
      dark: darken('#0096EF', 0.15),
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#0096EF',
      dark: darken('#0096EF', 0.15),
      contrastText: '#fff',
    },
    action: {
      default: '#0096EF',
      info: blue[200],
      success: green[600],
      warning: yellow[400],
      error: red[600],
    },
  },
  common: {
    white: '#fff',
    black: '#222',
  },
})

console.log('theme', theme)

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#root'),
)
