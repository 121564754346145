import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    boxShadow: theme.shadows[0],
  },
})

const Home = props => (
  <Paper className={props.classes.paper}>
    <Grid container>
      <Grid item>
        <Typography variant="body1">Logged in as {props.auth.email}</Typography>
        <Typography variant="body1">
          Current session expires at {String(new Date(props.auth.expires))}
        </Typography>
      </Grid>
    </Grid>
  </Paper>
)

const mapStateToProps = ({ auth, schema }) => ({ auth, schema })
const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Home))
