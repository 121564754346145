import React from 'react'
import findIndex from 'lodash/findIndex'

const selectable = WrappedComponent => {
  class Selectable extends React.Component {
    state = {
      selected: [],
    }

    addToSelected = (e, _entry) => {
      e.preventDefault()
      e.stopPropagation()

      const entry = { id: _entry.id, type: _entry.type }
      const { selected } = this.state
      const selectedIndex = findIndex(selected, {
        id: entry.id,
        type: entry.type,
      })

      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, entry)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      this.setState({ selected: newSelected })
    }

    handleSelectAllClick = collection => e => {
      if (e.target.checked) {
        this.setState({
          selected: collection
            .map(entry => {
              // Handle tables with pagination since those items haven't been
              // loaded into the view yet, and don't have an ID or type attr
              if (!entry.id || !entry.type) return null
              return {
                id: entry.id,
                type: entry.type,
              }
            })
            .filter(Boolean),
        })
        return
      }
      this.setState({ selected: [] })
    }

    clearSelected = () => this.setState({ selected: [] })

    isSelected = ({ id, type }) =>
      findIndex(this.state.selected, { id, type }) !== -1

    render() {
      return (
        <WrappedComponent
          selected={this.state.selected}
          isSelected={this.isSelected}
          clearSelected={this.clearSelected}
          addToSelected={this.addToSelected}
          handleSelectAllClick={this.handleSelectAllClick}
          {...this.props}
        />
      )
    }
  }

  return Selectable
}

export default selectable
