import React from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { login } from '../../reducers/api'
import { save } from '../../reducers/auth'
import { show } from '../../reducers/alert'
import { form } from '../../styles'

class Login extends React.Component {
  state = { email: '', password: '', message: '' }
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    const { email, password } = this.state
    const user = { email, password }
    this.props.login(user).then(response => {
      if (response.status === 200 || response.status === 201) {
        this.props.save(response.data)
      } else {
        this.setState({ message: 'Incorrect credentials.' })
      }
    })
  }

  handleChange(e) {
    const { name, value } = e.currentTarget
    this.setState({ [name]: value, message: '' })
  }

  render() {
    const { email, password, message } = this.state
    const { classes } = this.props
    return (
      <form name="login" onSubmit={this.handleSubmit}>
        <Grid container className={classes.loginContainer}>
          {message && (
            <Grid item className={classes.loginItem}>
              <Typography>{message}</Typography>
            </Grid>
          )}

          <Grid item className={classes.loginItem}>
            <FormControl className={classes.loginFormControl}>
              <Input
                disableUnderline
                required
                placeholder="Email"
                onChange={this.handleChange}
                classes={{ input: classes.loginInputInput }}
                value={email}
                disabled={this.props.processing}
                type="email"
                name="email"
                id="email"
              />
            </FormControl>
          </Grid>
          <Grid item className={classes.loginItem}>
            <FormControl className={classes.loginFormControl}>
              <Input
                disableUnderline
                required
                placeholder="Password"
                onChange={this.handleChange}
                classes={{ input: classes.loginInputInput }}
                value={password}
                disabled={this.props.processing}
                type="password"
                name="password"
                id="password"
              />
            </FormControl>
            <Grid item className={classes.loginItem}>
              <Button
                disableRipple
                type="submit"
                size="small"
                variant="contained"
                className={classNames(
                  classes.button,
                  classes.loginButton,
                  classes.default,
                )}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      save,
      show,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(form)(Login))
