import React from 'react'
import findIndex from 'lodash/findIndex'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core'
import { form } from '../../styles'
import { FormField } from '.'

class FormFieldsTags extends React.Component {
  state = { name: '' }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleChange(e) {
    this.setState({ name: e.currentTarget.value })
  }

  handleClick(e) {
    e.preventDefault()

    const { name } = this.state

    this.props.handleAddChip(e, 'tags', { id: name, name })
    this.setState({ name: '' })
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Toolbar className={classes.toolBarNested}>
          <Typography className={classes.formLabelTypography} noWrap>
            Tags
          </Typography>
        </Toolbar>
        <Toolbar className={classes.toolBarNested}>
          <Grid container style={{ alignItems: 'flex-end' }}>
            <Grid item sm={12}>
              <FormControl
                className={classes.formControl}
                style={{ paddingRight: 40 }}
              >
                <Input
                  type="text"
                  disableUnderline
                  placeholder="Add New"
                  disabled={this.props.processing}
                  onChange={this.handleChange}
                  value={this.state.name}
                  style={{ width: '100%' }}
                  classes={{ input: classes.inputInput }}
                  onKeyDown={e => {
                    if (e.which !== 13 /* Return */) return

                    this.handleClick(e)
                  }}
                />
              </FormControl>

              <Fab
                disableRipple
                onClick={this.handleClick}
                className={this.props.classes.buttonFab}
                style={{ position: 'absolute', top: -7, right: 0 }}
                disabled={
                  !this.state.name ||
                  this.props.processing ||
                  findIndex(this.props.tags, {
                    name: this.state.name,
                  }) > -1
                }
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Toolbar>

        <Grid container>
          <Grid item style={{ padding: '0 14px', marginTop: 16 }}>
            <div className={this.props.classes.chips}>
              {this.props.tags.map((tag, i) => {
                const inputType = 'chip'
                const data = this.props.createInputData({
                  id: tag.id,
                  name: 'tags',
                  value: tag.name,
                  label: tag.name,
                  checked: null,
                  inputType,
                  collection: 'tags',
                  readonly: false,
                  options: [],
                })

                return (
                  <FormField
                    key={`${tag.id}-${i}`}
                    item={data}
                    type={inputType}
                    schema={this.props.schema}
                    classes={classes}
                    createInputData={this.props.createInputData}
                    processing={this.props.processing}
                    handleChange={this.props.handleChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                    handleDeleteChip={this.props.handleDeleteChip}
                    handleAddChip={this.props.handleAddChip}
                    handleRemoveItem={this.props.handleRemoveItem}
                  />
                )
              })}
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(form)(FormFieldsTags)
