import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Chip from '@material-ui/core/Chip'
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/core'
import { Permit } from '..'
import { form } from '../../styles'

const FormChip = props => (
  <FormControlLabel
    key={props.item.value.name}
    control={
      <Permit
        permissions={764}
        readOnly={props.item.readonly}
        required={props.item.required}
        editable={
          <Chip
            label={props.item.value}
            onDelete={e => props.handleDeleteChip(e, props.item)}
            className={props.classes.chip}
            deleteIcon={<CancelIcon style={{ fontSize: 16 }} />}
          />
        }
        viewable={
          <Chip className={props.classes.chip} label={props.item.value} />
        }
      />
    }
  />
)

export default withStyles(form)(FormChip)
