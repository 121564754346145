import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { titleCase } from '../../util'

const SearchModels = props => (
  <Grid item sm={6}>
    <Typography
      color="inherit"
      variant="subtitle1"
      style={{ margin: '12px 0' }}
    >
      Collections
    </Typography>
    {Object.keys(props.mappings).map(name => (
      <FormControlLabel
        key={name}
        name={name}
        label={titleCase(name)}
        control={
          <Checkbox
            style={{ padding: 8 }}
            value={name}
            disableRipple={true}
            disabled={props.processing}
            checked={props.models.includes(name)}
            onChange={props.onChange}
          />
        }
      />
    ))}
  </Grid>
)

export default SearchModels
