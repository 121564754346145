export const CAROUSEL_OPEN = 'alert/CAROUSEL_OPEN'
export const CAROUSEL_CLOSE = 'alert/CAROUSEL_CLOSE'

const initialState = {
  visible: false,
  id: 0,
}

export const open = item => dispatch => {
  const { id, mime_type } = item
  dispatch({
    type: CAROUSEL_OPEN,
    payload: { id, mime_type },
  })
}

export const close = () => dispatch => {
  dispatch({
    type: CAROUSEL_CLOSE,
    payload: {},
  })
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CAROUSEL_OPEN:
      return {
        visible: true,
        ...action.payload,
      }
    case CAROUSEL_CLOSE:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
