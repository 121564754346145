export const ALERT_SHOW = 'alert/ALERT_SHOW'
export const ALERT_HIDE = 'alert/ALERT_HIDE'
export const ALERT_RESET = 'alert/ALERT_RESET'

const transitionDuration = 400
const autoHideDuration = 3000

let hideTimer = null
let resetTimer = null

const initialState = {
  visible: false,
  variant: 'info',
  message: '',
}

export const show = ({ variant, message }) => dispatch => {
  dispatch({
    type: ALERT_SHOW,
    payload: { variant, message },
  })
  clearTimeout(hideTimer)
  hideTimer = setTimeout(
    () =>
      dispatch({
        type: ALERT_HIDE,
      }),
    autoHideDuration,
  )
}

export const hide = () => dispatch => {
  dispatch({
    type: ALERT_HIDE,
    payload: {},
  })
  clearTimeout(resetTimer)
  resetTimer = setTimeout(
    () =>
      dispatch({
        type: ALERT_RESET,
      }),
    transitionDuration,
  )
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT_SHOW:
      return {
        visible: true,
        ...action.payload,
      }
    case ALERT_HIDE:
      return {
        ...state,
        visible: false,
      }
    case ALERT_RESET:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
