import React from 'react'
import Button from '@material-ui/core/Button'
import { FixedHeader } from '../'

const SearchActions = props => (
  <FixedHeader>
    <Button
      disableRipple
      size="small"
      className={props.classes.button}
      variant="outlined"
      onClick={props.reset}
    >
      Reset
    </Button>
    <Button
      disableRipple
      size="small"
      variant="outlined"
      className={props.classes.button}
      onClick={props.toggleAdvanced}
    >
      {props.advanced ? 'Simple' : 'Advanced'}
    </Button>
    <Button
      disableRipple
      size="small"
      className={props.classes.button}
      variant="contained"
      color="primary"
      onClick={props.handleSubmit}
    >
      Search
    </Button>
  </FixedHeader>
)

export default SearchActions
