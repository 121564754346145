export const SPINNER_FREEZE = 'alert/SPINNER_FREEZE'
export const SPINNER_UNFREEZE = 'alert/SPINNER_UNFREEZE'

const initialState = {
  visible: false,
}

export const freeze = () => dispatch =>
  dispatch({
    type: SPINNER_FREEZE,
  })

export const unfreeze = () => dispatch =>
  dispatch({
    type: SPINNER_UNFREEZE,
  })

export default (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_FREEZE:
      return {
        ...state,
        visible: true,
      }
    case SPINNER_UNFREEZE:
      return {
        ...state,
        visible: false,
      }
    default:
      return state
  }
}
