import React from 'react'
import FormInput from './FormInput'
import FormSelect from './FormSelect'
import FormCheckbox from './FormCheckbox'
import FormTextField from './FormTextField'
import FormChip from './FormChip'

const FormField = props => {
  const { type } = props
  switch (type) {
    case 'string':
    case 'text':
    case 'decimal': // TODO
    case 'float': // TODO
    case 'date':
    case 'number':
    case 'password':
      // case 'integer':
      // case 'datetime':
      return (
        <FormInput
          item={props.item}
          context={props.context}
          gallery={props.gallery}
          classes={props.classes}
          processing={props.processing}
          handleChange={props.handleChange}
          handleNestedItemChange={props.handleNestedItemChange}
        />
      )
    case 'select':
      return (
        <FormSelect
          item={props.item}
          context={props.context}
          classes={props.classes}
          processing={props.processing}
          handleChange={props.handleChange}
          handleNestedItemChange={props.handleNestedItemChange}
        />
      )
    case 'checkbox':
      return (
        <FormCheckbox
          item={props.item}
          context={props.context}
          // classes={props.classes}
          processing={props.processing}
          handleCheckboxChange={props.handleCheckboxChange}
        />
      )
    case 'textarea':
      return (
        <FormTextField
          item={props.item}
          context={props.context}
          gallery={props.gallery}
          classes={props.classes}
          processing={props.processing}
          handleChange={props.handleChange}
          handleNestedItemChange={props.handleNestedItemChange}
        />
      )
    case 'chip':
      return (
        <FormChip
          item={props.item}
          context={props.context}
          classes={props.classes}
          processing={props.processing}
          handleChange={props.handleChange}
          handleNestedItemChange={props.handleNestedItemChange}
          handleDeleteChip={props.handleDeleteChip}
          handleRemoveItem={props.handleRemoveItem}
          handleAddChip={props.handleAddChip}
        />
      )
    default:
      console.warn('Invalid field type', type, 'for', props.item.name)
      return null
  }
}

export default FormField
