import React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormField } from '.'
import { getInputType } from '../../util'

const FormFields = props => {
  const { item, type, schema } = props

  return (
    <Grid container spacing={1}>
      {Object.entries(item).map(child => {
        const [name, value] = child

        if (typeof schema[type][name] === 'undefined') {
          return null
        }

        if (schema[type][name].hidden) return null

        const { label, fieldType } = schema[type][name]
        const inputType = getInputType(fieldType)
        const data = props.createInputData({
          id: item.id,
          name,
          value,
          label,
          inputType,
          collection: props.collection,
          ...schema[type][name],
        })

        return (
          <Grid key={`${item.id}-${name}`} item sm={props.fieldWidth || 6}>
            <FormField
              style={{ width: '100%' }}
              item={data}
              context={item}
              type={inputType}
              gallery={props.gallery}
              classes={props.classes}
              processing={props.processing}
              handleChange={props.handleChange}
              handleNestedItemChange={props.handleNestedItemChange}
              handleFileChange={props.handleFileChange}
              handleCheckboxChange={props.handleCheckboxChange}
              createInputData={props.createInputData}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default FormFields
