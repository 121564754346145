import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { post } from '../reducers/api'
import { freeze, unfreeze } from '../reducers/spinner'
import { handleDownload } from '../util'

const exportable = WrappedComponent => {
  class Exportable extends React.Component {
    // Items can be passed into createExport, or it will grab selected by
    // default

    createExport = (items = []) => {
      let selected = [...(items && items.length ? items : this.props.selected)]
      if (!selected.length) return

      // Previewables (or any 'acts_as_list' item) will have position returned
      // by the API
      if (selected[0].position !== typeof 'undefined') {
        selected.sort((a, b) => a.position - b.position)
      }

      selected = items.map(item => ({ id: item.id, type: item.type }))

      this.props.freeze()

      this.props
        .post('/export', { documents: selected })
        .then(({ data }) => handleDownload(data))
        .then(() => {
          this.props.clearSelected()
          this.props.unfreeze()
        })
    }

    render() {
      return (
        <WrappedComponent createExport={this.createExport} {...this.props} />
      )
    }
  }

  return connect(
    () => ({}),
    dispatch => bindActionCreators({ post, freeze, unfreeze }, dispatch),
  )(Exportable)
}

export default exportable
