import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem, PreviewsTable } from '..'

class Preview extends React.Component {
  static defaultProps = {
    item: {
      id: null,
      name: '',
      count: '',
      items: [],
    },
  }
  render() {
    return (
      <CollectionItem
        type="preview"
        sidebarBottom={<PreviewsTable />}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(Preview)
