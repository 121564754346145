import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Permit } from '../'
import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const FormMediaActions = props => (
  <React.Fragment>
    <IconButton onClick={() => props.handleEdit(props.item)}>
      <EditIcon fontSize="small" />
    </IconButton>

    <CopyToClipboard
      text={props.item.urls.large || props.item.urls.source}
      onCopy={props.handleCopy}
    >
      <IconButton>
        <ShareIcon fontSize="small" />
      </IconButton>
    </CopyToClipboard>

    <IconButton onClick={() => props.handleDownload(props.item)}>
      <SaveAltIcon fontSize="small" />
    </IconButton>

    <Permit
      permissions={764}
      editable={
        <IconButton onClick={() => props.handleDeleteItem(props.item)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      }
    />

    <Permit
      permissions={764}
      editable={
        <IconButton onClick={() => props.handleToggleVisibility(props.item)}>
          {props.item.public ? (
            <VisibilityIcon fontSize="small" />
          ) : (
            <VisibilityOffIcon fontSize="small" />
          )}
        </IconButton>
      }
    />
  </React.Fragment>
)

export default FormMediaActions
