import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { image } from '../styles'

const Thumbnail = props => {
  return props.thumbnail ? (
    <img
      alt="Thumbnail"
      className={props.classes[props.size || 'small']}
      style={props.style ? props.style : {}}
      src={props.thumbnail.urls[props.size || 'small']}
    />
  ) : null
}

export default withStyles(image)(Thumbnail)
