import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import Checkbox from '@material-ui/core/Checkbox'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import selectable from './selectable'
import previewable from './previewable'

import {
  Thumbnail,
  CollectionTableHead,
  CollectionTableToolbar,
  Permit,
} from '.'
import { titleCase } from '../util'
import { withStyles } from '@material-ui/core'
import { table } from '../styles'
import { get, patch } from '../reducers/api'
import { freeze, unfreeze } from '../reducers/spinner'

const toolbarStyle = {
  padding: 0,
  height: 32,
  minHeight: 32,
  marginTop: 8,
  marginBottom: 8,
}

const inlineButtonStyle = {
  padding: 8,
  float: 'right',
}

class RelatedEntries extends React.Component {
  static defaultProps = { items: [] }

  state = {
    order: 'asc',
    orderBy: 'id',
  }

  handleRequestSort = (e, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  render() {
    const rows = [
      { id: 'id', label: 'ID' },
      { id: 'sku', label: 'SKU' },
      { id: 'thumbnail', label: 'Thumbnail' },
      { id: 'title', label: 'Title' },
      { id: 'type', label: 'Type' },
    ]

    const { order, orderBy } = this.state
    const { type, history, classes, selected, item } = this.props
    const { tableCell, tableFixed, formLabelTypography } = classes
    const numSelected = selected.length

    if (!item.items || !item.items.length) return null

    return (
      <React.Fragment>
        <CollectionTableToolbar
          type={type}
          history={history}
          togglePreviewDialog={this.props.togglePreviewDialog}
          numSelected={numSelected}
          absolute={false}
          crudable={false}
          selectable={type !== 'preview'}
          previewable={type !== 'preview'}
          downloadable={false}
          withoutHeader={true}
          headerStyles={toolbarStyle}
          header={
            <Toolbar style={toolbarStyle}>
              <Typography className={formLabelTypography} noWrap>
                Entries
              </Typography>
            </Toolbar>
          }
        />

        <Grid container spacing={0}>
          <Grid item sm={12}>
            <Table className={tableFixed}>
              <CollectionTableHead
                rows={rows}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.props.handleSelectAllClick(item.items)}
                onRequestSort={this.handleRequestSort}
                rowCount={item.items.length}
                type={'related'}
                classes={this.props.classes}
                selectable={type !== 'preview'}
                previewable={type !== 'preview'}
                downloadable={type !== 'preview'}
                removable={this.props.removable}
              />
              <TableBody>
                {item.items.map(item => {
                  const isSelected = this.props.isSelected(item)
                  return (
                    <TableRow
                      hover
                      key={`${item.type}-${item.id}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.props.history.push(`/${item.type}/${item.id}`)
                      }
                      className={tableCell}
                    >
                      {type !== 'preview' && (
                        <Permit permissions={774}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={e => this.props.addToSelected(e, item)}
                              checked={isSelected}
                            />
                          </TableCell>
                        </Permit>
                      )}

                      <TableCell className={tableCell}>{item.id}</TableCell>
                      <TableCell className={tableCell}>{item.sku}</TableCell>
                      <TableCell className={tableCell}>
                        <Thumbnail size="small" thumbnail={item.thumbnail} />
                      </TableCell>
                      <TableCell className={tableCell}>{item.title}</TableCell>
                      <TableCell className={tableCell}>
                        {titleCase(item.type)}
                      </TableCell>
                      {this.props.removable && (
                        <TableCell className={tableCell}>
                          <IconButton
                            style={inlineButtonStyle}
                            onClick={e => this.props.handleRemoveItem(e, item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default connect(
  () => ({}),
  dispatch => bindActionCreators({ get, patch, freeze, unfreeze }, dispatch),
)(withRouter(selectable(previewable(withStyles(table)(RelatedEntries)))))
