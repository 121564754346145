import React from 'react'
import PropTypes from 'prop-types'
import {
  EditorState,
  convertToRaw,
  ContentState,
  Modifier,
  SelectionState,
  convertFromHTML,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { getSelectedBlocksMap } from 'draftjs-utils'
import { withStyles } from '@material-ui/core/styles'
import { form } from '../../styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../editor.css'

const removeBlockTypes = editorState => {
  const contentState = editorState.getCurrentContent()
  const blocksMap = getSelectedBlocksMap(editorState)
  const contentWithoutBlocks = blocksMap.reduce((newContentState, block) => {
    const blockType = block.getType()

    const characterList = block.getCharacterList()
    const updatedCharacterList = characterList.map(c =>
      c.set('style', c.get('style').clear()),
    )

    const updatedBlock = block.set('characterList', updatedCharacterList)

    if (blockType !== 'unstyled') {
      console.log('unstyled', updatedBlock.getText())

      const selectionState = SelectionState.createEmpty(updatedBlock.getKey())
      const updatedSelection = selectionState.merge({
        focusOffset: 0,
        anchorOffset: updatedBlock.getText().length,
      })

      return Modifier.setBlockType(
        newContentState,
        updatedSelection,
        'unstyled',
      )
    }

    return newContentState
  }, contentState)

  const newEditorState = EditorState.push(
    editorState,
    contentWithoutBlocks,
    'change-block-type',
  )

  return newEditorState
}

const styles = ['BOLD', 'ITALIC', 'UNDERLINE']

const removeInlineStyles = editorState => {
  const contentState = editorState.getCurrentContent()
  const contentWithoutStyles = styles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(
        newContentState,
        editorState.getSelection(),
        style,
      ),
    contentState,
  )

  const newEditorState = EditorState.push(
    editorState,
    contentWithoutStyles,
    'change-inline-style',
  )

  return newEditorState
}

const propTypesStringOrNull = (props, propName, componentName) => {
  const propValue = props[propName]
  if (propValue === null || typeof propValue === 'string') return
  return new Error(`${componentName} only accepts null or string`)
}

class Wysiwyg extends React.Component {
  static defaultProps = {
    initialEditorState: '',
    // toolbar,
    item: {},
  }
  static propTypes = {
    initialEditorState: propTypesStringOrNull,
    // toolbar: PropTypes.object.isRequired,
    item: PropTypes.object,
  }

  state = { editing: false, editorState: EditorState.createEmpty() }

  constructor(props) {
    super(props)
    this.onEditorStateChange = this.onEditorStateChange.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidMount() {
    this.setEditorState(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialEditorState &&
      this.props.initialEditorState === Wysiwyg.defaultProps.initialEditorState
    ) {
      this.setEditorState(nextProps)
    }
  }

  setEditorState = ({ initialEditorState }) => {
    if (!initialEditorState) return

    const contentBlock = convertFromHTML(initialEditorState)
    if (!contentBlock || !contentBlock.contentBlocks) return

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    )

    const editorState = EditorState.createWithContent(contentState)
    this.setState({ editorState })
  }

  getHTML = () =>
    draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

  handleOnChange = () =>
    this.props.onChange({
      html: this.getHTML(),
    })

  handleClick = e => {
    e.preventDefault()
    this.setState({ editing: !this.state.editing })
  }

  onEditorStateChange = editorState =>
    this.setState({ editorState }, this.handleOnChange)

  render() {
    const { editorState } = this.state
    const { classes } = this.props
    return (
      <FormControl className={classes.formControl} style={{ minHeight: 32 }}>
        <InputLabel
          shrink={false}
          disableAnimation={true}
          className={classes.formControlLabel}
          style={{ position: 'relative' }}
          htmlFor={this.props.item.name}
        >
          {this.props.item.label}
        </InputLabel>
        {this.state.editing ? (
          <Editor
            className={classes.wysiwygEditor}
            editorState={editorState}
            toolbar={{
              options: [
                'inline',
                'list',
                'textAlign',
                'link',
                'remove',
                'history',
                'embedded', // using this for custom close button
              ],
              inline: {
                inDropdown: false,
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
              list: {
                inDropdown: true,
                options: ['unordered', 'ordered', 'indent', 'outdent'],
              },
              textAlign: {
                inDropdown: true,
                options: ['left', 'center', 'right', 'justify'],
              },
              remove: {
                component: () => (
                  <div
                    className="rdw-option-wrapper"
                    onClick={() => {
                      // remove block and inline styles from selection only
                      let { editorState } = this.state
                      editorState = removeInlineStyles(editorState)
                      editorState = removeBlockTypes(editorState)

                      // then convert back and forth between html markup as a
                      // kludge against having to parse complicated html
                      // structures by simply removing any inline html
                      const markup = draftToHtml(
                        convertToRaw(editorState.getCurrentContent()),
                      )
                      const blocksFromHTML = convertFromHTML(markup)

                      if (!blocksFromHTML) return

                      const contentState = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap,
                      )
                      const nextEditorState = EditorState.createWithContent(
                        contentState,
                      )

                      this.onEditorStateChange(nextEditorState)
                    }}
                  >
                    <img
                      alt=""
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNOC4xIDE0bDYuNC03LjJjLjYtLjcuNi0xLjgtLjEtMi41bC0yLjctMi43Yy0uMy0uNC0uOC0uNi0xLjMtLjZIOC42Yy0uNSAwLTEgLjItMS40LjZMLjUgOS4yYy0uNi43LS42IDEuOS4xIDIuNWwyLjcgMi43Yy4zLjQuOC42IDEuMy42SDE2di0xSDguMXptLTEuMy0uMXMwLS4xIDAgMGwtMi43LTIuN2MtLjQtLjQtLjQtLjkgMC0xLjNMNy41IDZoLTFsLTMgMy4zYy0uNi43LS42IDEuNy4xIDIuNEw1LjkgMTRINC42Yy0uMiAwLS40LS4xLS42LS4yTDEuMiAxMWMtLjMtLjMtLjMtLjggMC0xLjFMNC43IDZoMS44TDEwIDJoMUw3LjUgNmwzLjEgMy43LTMuNSA0Yy0uMS4xLS4yLjEtLjMuMnoiLz48L3N2Zz4="
                    />
                  </div>
                ),
              },
              embedded: {
                component: () => (
                  <div className="rdw-option-wrapper">
                    <CheckCircleOutlineIcon onClick={this.handleClick} />
                  </div>
                ),
              },
            }}
            onEditorStateChange={this.onEditorStateChange}
          />
        ) : (
          <div onClick={this.handleClick} className={classes.wysiwygContent}>
            <div dangerouslySetInnerHTML={{ __html: this.getHTML() }} />
          </div>
        )}
      </FormControl>
    )
  }
}

export default withStyles(form)(Wysiwyg)
