import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core'
import { Thumbnail } from '../components'
import { titleCase } from '../util'
import { table } from '../styles'

const Categorizations = props => {
  const { tableCell, tableFixed } = props.classes

  return (
    <Grid item sm={12}>
      <Toolbar
        style={{
          paddingLeft: 0,
          height: 32,
          minHeight: 32,
          marginTop: 8,
          marginBottom: 8,
        }}
      >
        <Typography
          style={{ color: 'rgba(0, 0, 0, 0.54)' }}
          variant="body1"
          noWrap
        >
          Entries
        </Typography>
      </Toolbar>
      <Grid container spacing={0}>
        <Grid item sm={12}>
          <Table className={tableFixed}>
            <TableHead>
              <TableRow>
                <TableCell className={tableCell}>SKU</TableCell>
                <TableCell className={tableCell}>Thumbnail</TableCell>
                <TableCell className={tableCell}>Title</TableCell>
                <TableCell className={tableCell}>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.item.items.map((a, i) => (
                <TableRow
                  hover
                  key={i}
                  onClick={() => props.history.push(`/${a.type}/${a.id}`)}
                  style={{ cursor: 'pointer' }}
                  // className={tableCell}
                >
                  <TableCell className={tableCell}>{a.sku}</TableCell>
                  <TableCell className={tableCell}>
                    <Thumbnail size="small" thumbnail={a.thumbnail} />
                  </TableCell>
                  <TableCell className={tableCell}>{a.title}</TableCell>
                  <TableCell className={tableCell}>
                    {titleCase(a.type)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  )
}

Categorizations.defaultProps = { items: [] }

export default connect(
  () => ({}),
  () => ({}),
)(withRouter(withStyles(table)(Categorizations)))
