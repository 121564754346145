import React from 'react'
import { Permit } from '../'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'
import { form } from '../../styles'

class FormInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleImmediateChange = this.handleImmediateChange.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.item.value !== this.props.item.value) {
      return true
    }

    return false
  }

  handleImmediateChange = e => {
    const { gallery, item } = this.props
    const { value } = e.currentTarget

    this.props.handleChange({ proxy: { ...item, value } }, gallery)
  }

  render() {
    const { classes } = this.props
    return this.props.item.hidden ? null : (
      <FormControl
        key={`${this.props.item.name}-${this.props.item.id}`}
        className={classes.formControl}
      >
        <InputLabel
          shrink={false}
          disableAnimation={true}
          className={classes.formControlLabel}
          htmlFor={this.props.item.name}
        >
          {this.props.item.label}
        </InputLabel>

        <Permit
          permissions={764}
          editable={
            <Input
              disableUnderline
              className={classes.input}
              classes={{ input: classes.inputInput }}
              disabled={this.props.processing}
              onChange={this.handleImmediateChange}
              type={this.props.item.inputType}
              id={this.props.item.name}
              name={this.props.item.name}
              value={this.props.item.value || ''}
              readOnly={this.props.item.readonly}
              required={this.props.item.required}
            />
          }
          viewable={
            <Input
              disableUnderline
              classes={{ input: classes.inputInput }}
              value={this.props.item.value || ''}
              required={this.props.item.required}
              readOnly={true}
            />
          }
        />
      </FormControl>
    )
  }
}

export default withStyles(form)(FormInput)
