import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import {
  Permit,
  CollectionTableToolbar,
  CollectionTableHead,
  Thumbnail,
} from '..'
import { table } from '../../styles'
import { get, post, patch } from '../../reducers/api'
import { freeze, unfreeze } from '../../reducers/spinner'
import { titleCase, stableSort, getSorting } from '../../util'
import selectable from '../selectable'
import previewable from '../previewable'
import exportable from '../exportable'

class SearchResults extends React.Component {
  state = {
    order: 'asc',
    orderBy: false,
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  render() {
    if (!this.props.results || !this.props.results.length) return null

    const selectable = true // TODO passed via props?
    const numSelected = this.props.selected.length
    const rowCount = this.props.results.length
    const { history, classes } = this.props
    const { order, orderBy } = this.state

    return (
      <React.Fragment>
        <CollectionTableToolbar
          type={'Result'}
          numSelected={numSelected}
          history={history}
          createExport={this.props.createExport}
          togglePreviewDialog={this.props.togglePreviewDialog}
          crudable={false}
          selectable={true}
          previewable={true}
          downloadable={true}
          withoutHeader={true}
          headerStyles={{
            top: 0,
            height: 32,
            minHeight: 32,
            paddingRight: 0,
          }}
        />

        <Table className={classes.tableFixed}>
          <CollectionTableHead
            numSelected={numSelected}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={this.props.handleSelectAllClick(
              this.props.results,
            )}
            onRequestSort={this.handleRequestSort}
            rowCount={rowCount}
            type="search"
            classes={classes}
            selectable={selectable}
            previewable={true}
          />

          <TableBody>
            {stableSort(this.props.results, getSorting(order, orderBy)).map(
              result => {
                const key = `${result.id}-${result.type}`
                const isSelected = this.props.isSelected(result)

                return (
                  <TableRow
                    hover
                    key={key}
                    style={{ cursor: 'pointer' }}
                    tabIndex={-1}
                    role="checkbox"
                    aria-checked={isSelected}
                    selected={isSelected}
                  >
                    <Permit permissions={774}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={e => this.props.addToSelected(e, result)}
                          checked={isSelected}
                        />
                      </TableCell>
                    </Permit>

                    <TableCell
                      className={classes.tableCell}
                      onClick={e => this.props.handleClick(e, result)}
                    >
                      {result.id}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={e => this.props.handleClick(e, result)}
                    >
                      <Thumbnail size="small" thumbnail={result.thumbnail} />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={e => this.props.handleClick(e, result)}
                    >
                      {result.title}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={e => this.props.handleClick(e, result)}
                    >
                      {result.sku}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={e => this.props.handleClick(e, result)}
                    >
                      {result._date}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={e => this.props.handleClick(e, result)}
                    >
                      {titleCase(result.type)}
                    </TableCell>
                  </TableRow>
                )
              },
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    )
  }
}

// export default SearchResults
export default connect(
  () => ({}),
  dispatch =>
    bindActionCreators({ get, post, patch, freeze, unfreeze }, dispatch),
)(selectable(previewable(exportable(withStyles(table)(SearchResults)))))
