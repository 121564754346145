import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import api from './api'
import auth from './auth'
import alert from './alert'
import search from './search'
import carousel from './carousel'
import schema from './schema'
import spinner from './spinner'
import mappings from './mappings'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    api,
    auth,
    alert,
    search,
    carousel,
    schema,
    spinner,
    mappings,
  })

export default createRootReducer
