import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem, RelatedEntries } from '..'
import { FormFieldsSidebarThumbnail, FormFieldsSidebarTags } from '../Form'

class Project extends React.Component {
  static defaultProps = {
    item: {
      id: null,
      title: '',
      name: '',
      slug: '',
      items: [],
      thumbnail: null,
      tags: [],
    },
  }

  render() {
    return (
      <CollectionItem
        type="project"
        sidebarRight={[
          <FormFieldsSidebarThumbnail key="thumbnail" />,
          <FormFieldsSidebarTags key="tags" />,
        ]}
        sidebarBottom={<RelatedEntries type="work" />}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(Project)
