import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Fade from '@material-ui/core/Fade'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { hide } from '../reducers/alert'
import { alert } from '../styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

class Alert extends React.Component {
  handleClose = () => {
    this.props.hide()
  }

  render() {
    const { visible, message, variant } = this.props.alert
    const { classes } = this.props
    const Icon = variantIcon[variant]

    return (
      <Snackbar
        open={visible}
        onClose={this.handleClose}
        TransitionComponent={Fade}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          className={classNames(classes.snackbar, classes[variant])}
          aria-describedby="client-snackbar"
          message={
            <span className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              <span className={classes.messages}>
                {Array.isArray(message)
                  ? message.map((msg, i) => <span key={i}>{msg}</span>)
                  : message}
              </span>
            </span>
          }
          action={[
            <IconButton
              key="close"
              size="small"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

const mapStateToProps = ({ alert }) => ({ alert })
const mapDispatchToProps = dispatch => bindActionCreators({ hide }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(alert)(Alert))
