import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { form } from '../../styles'
import { FormField } from '.'
import { getInputType } from '../../util'
import { roles } from '../../constants'

const GridItem = props => {
  const { item, ...rest } = props
  return (
    <Grid item sm={12}>
      <FormField
        item={rest}
        context={item}
        type={props.inputType}
        gallery={props.gallery}
        classes={props.classes}
        processing={props.processing}
        handleChange={props.handleChange}
        handleNestedItemChange={props.handleNestedItemChange}
        handleFileChange={props.handleFileChange}
        handleCheckboxChange={props.handleCheckboxChange}
        createInputData={props.createInputData}
      />
    </Grid>
  )
}

class FormFields extends React.Component {
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.item) !== JSON.stringify(this.props.item)
  }

  render() {
    const { item, type, schema, classes } = this.props

    const values = Object.entries(item).reduce((acc, [name, value]) => {
      if (
        typeof schema[type][name] === 'undefined' ||
        schema[type][name].hidden
      ) {
        return acc
      }
      const { label, fieldType } = schema[type][name]
      const inputType = getInputType(fieldType)
      const data = this.props.createInputData({
        id: item.id,
        name,
        value,
        label,
        inputType,
        collection: this.props.collection,
        ...schema[type][name],
      })

      acc[name] = data
      return acc
    }, {})

    const headers = {
      SALES: 'Sales Information',
      LOCATION: 'Location',
      DIMENSIONS: 'Dimensions',
      DETAILS: 'Details',
    }

    const {
      sales_status,
      sale_price,
      sales_collection,
      sale_date,
      sale_buyer,
      production_cost,
      currency,

      location_storage,
      location_gallery,
      location_other,

      measurements,
      height,
      width,
      length,
      weight,

      credits,
      notes,

      ...rest
    } = values

    const groups = {
      dimensions: {
        header: headers.DIMENSIONS,
        values: [measurements, height, width, length, weight].filter(Boolean),
      },

      location: {
        header: headers.LOCATION,
        values: [location_storage, location_gallery, location_other].filter(
          Boolean,
        ),
      },

      sales: {
        header: headers.SALES,
        values: [
          sales_status,
          sale_price,
          sales_collection,
          sale_date,
          sale_buyer,
          production_cost,
          currency,
        ].filter(Boolean),
      },

      details: {
        header: headers.DETAILS,
        values: [credits, notes].filter(Boolean),
      },
    }

    // TODO this should be handled better. Disallow non-admin from seeing sales
    // data
    if (roles[this.props.auth.role] > 0) {
      groups.sales.values = []
    }

    return (
      <Grid container>
        <Grid container spacing={1}>
          {Object.values(rest).map((entry, i) => (
            <GridItem key={i} {...this.props} {...entry} />
          ))}
        </Grid>

        {Object.values(groups).map(group => {
          return group.values.length < 1 ? null : (
            <ExpansionPanel
              key={group.header}
              defaultExpanded={false}
              classes={{
                root: classes.expansionPanelRoot,
                expanded: classes.expansionPanelExpanded,
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.expansionSummaryPanelRoot,
                  expanded: classes.expansionPanelSummaryExpanded,
                }}
              >
                <Typography className={classes.formLabelTypography} noWrap>
                  {group.header}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                <Grid container spacing={1}>
                  {group.values.map((value, j) =>
                    !value ? null : (
                      <GridItem key={j} {...this.props} {...value} />
                    ),
                  )}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </Grid>
    )
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  () => ({}),
)(withStyles(form)(FormFields))
