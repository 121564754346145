import React from 'react'
import { FormFieldsGrouped, FormFieldsMixed } from '.'
import { form } from '../../styles'
import { withStyles } from '@material-ui/core'

const FormFields = props => {
  if (!Object.keys(props.schema).length) return null

  return props.grouped ? (
    <FormFieldsGrouped {...props} />
  ) : (
    <FormFieldsMixed {...props} />
  )
}

export default withStyles(form)(FormFields)
