import React from 'react'
import { Permit } from '../'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { form } from '../../styles'

class FormSelect extends React.Component {
  constructor(props) {
    super(props)
    this.handleImmediateChange = this.handleImmediateChange.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.item.value !== this.props.item.value) {
      return true
    }

    return false
  }

  handleImmediateChange(e) {
    const { value } = e.target
    const { item } = this.props
    this.props.handleChange({ proxy: { ...item, value } })
  }

  render() {
    const { classes } = this.props

    return (
      <Permit
        permissions={764}
        editable={
          <FormControl
            key={this.props.item.name}
            className={classes.formControl}
          >
            <InputLabel
              shrink={false}
              disableAnimation={true}
              className={classes.formControlLabel}
              htmlFor={this.props.item.name}
            >
              {this.props.item.label}
            </InputLabel>
            <Select
              disableUnderline
              className={classes.input}
              value={this.props.item.value}
              disabled={this.props.processing}
              onChange={this.handleImmediateChange}
              readOnly={this.props.item.readonly}
              required={this.props.item.required}
              classes={{
                root: classes.selectRoot,
                select: classes.selectSelect,
              }}
              inputProps={{
                name: this.props.item.name,
                id: this.props.item.name,
              }}
              MenuProps={{
                classes: {
                  paper: classes.selectMenuList,
                },
              }}
            >
              {this.props.item.options.map((a, i) => (
                <MenuItem
                  disableRipple
                  classes={{
                    root: classes.selectMenuListItem,
                    selected: classes.selectMenuListItemSelected,
                  }}
                  key={`${this.props.item.id}-${a.id}-${i}`}
                  value={a.value}
                >
                  {a.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        viewable={
          <FormControl
            key={this.props.item.name}
            className={classes.formControl}
          >
            <InputLabel htmlFor={this.props.item.name}>
              {this.props.item.label}
            </InputLabel>
            <Select
              disableUnderline
              value={this.props.item.value}
              readOnly={true}
              required={this.props.item.required}
              classes={{
                root: classes.selectRoot,
                select: classes.selectSelect,
              }}
              inputProps={{
                name: this.props.item.name,
                id: this.props.item.name,
              }}
              MenuProps={{
                classes: {
                  paper: classes.selectMenuList,
                },
              }}
            >
              {this.props.item.options.map((a, i) => (
                <MenuItem
                  disableRipple
                  classes={{
                    root: classes.selectMenuListItem,
                    selected: classes.selectMenuListItemSelected,
                  }}
                  key={`${this.props.item.id}-${a.id}-${i}`}
                  value={a.value}
                >
                  {a.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      />
    )
  }
}

export default withStyles(form)(FormSelect)
