import React from 'react'
import { FormMedia } from '.'
import Grid from '@material-ui/core/Grid'

const FormFieldsSidebarMediaTabs = props => (
  <Grid container spacing={0}>
    <Grid item sm={12}>
      <FormMedia {...props} />
    </Grid>
  </Grid>
)

export default FormFieldsSidebarMediaTabs
