import React from 'react'
// import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { search } from '../../styles'
import { get } from '../../reducers/api'
import {
  createStatePartialFromQueryString,
  safeEncodeURIComponent,
  appendFiltersToQueryString,
  // titleCase,
} from '../../util'

import withStyles from '@material-ui/core/styles/withStyles'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'

// const CheckboxFilter = props => {
//   return (
//     <FormControlLabel
//       className={classNames(props.classes.formControl)}
//       label={titleCase(props.name)}
//       control={
//         <Checkbox
//           value={props.name}
//           checked={
//             props.filters.has(props.filter) &&
//             props.filters.get(props.filter).indexOf(props.name) > -1
//           }
//           onChange={e => props.handleFilterChange(e, props.filter)}
//         />
//       }
//     />
//   )
// }

class FormSearch extends React.Component {
  state = { query: '', filters: new Map(), disabled: false, results: [] }

  componentDidMount() {
    const search = createStatePartialFromQueryString(
      this.props.history.location.search,
    )

    if (search && search.query) {
      this.setState(() => ({
        query: search.query,
        filters: search.filters,
      }))
    }
  }

  getResults = () => {
    const { query, filters } = this.state
    const query_ = appendFiltersToQueryString(
      safeEncodeURIComponent(query),
      filters,
    )

    const search = `?s=${query_}`

    this.props.history.push({
      pathname: '/search',
      search,
    })

    this.setState(() => ({ disabled: false }))
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState(() => ({ disabled: true }), this.getResults)
  }

  handleChange = e => {
    const { value } = e.currentTarget
    this.setState(() => ({
      query: value,
    }))
  }

  handleFilterChange = (e, filter) => {
    const { checked, value } = e.currentTarget
    const { filters } = this.state
    const current = filters.get(filter) || []
    this.setState({
      filters: filters.set(
        filter,
        checked ? [...current, value] : current.filter(a => a !== value),
      ),
    })
  }

  render() {
    return (
      <React.Fragment>
        <form id="search" onSubmit={this.handleSubmit}>
          <div>
            {React.cloneElement(this.props.children, {
              onChange: this.handleChange,
              disabled: this.state.disabled,
              value: this.state.query,
            })}
          </div>
        </form>
        {/* <div>
          {Object.values(collections).map(a => (
            <CheckboxFilter
              key={a.name}
              name={a.name}
              classes={this.props.classes}
              filters={this.state.filters}
              filter="models"
              handleFilterChange={this.handleFilterChange}
            />
          ))}
        </div> */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      get,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(search)(FormSearch)))
