import React from 'react'
import { createMultipleSelectOptions } from '../../util'
import { FormFieldsCollectionSelect } from '.'

const FormFieldsSidebarExhibitions = props => {
  return (
    <FormFieldsCollectionSelect
      collection="exhibitions"
      options={createMultipleSelectOptions(props, 'exhibitions')}
      item={props.item}
      type={props.type}
      exhibitions={props.exhibitions}
      schema={props.schema}
      classes={props.classes}
      createInputData={props.createInputData}
      processing={props.processing}
      handleChange={props.handleChange}
    />
  )
}

export default FormFieldsSidebarExhibitions
