const initialState = {
  work: {},
  exhibition: {},
  publication: {},
  edition: {},
  project: {},
  category: {},
  document: {},
  image: {},
  pdf: {},
  video: {},
  user: {},
  preview: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
