import React from 'react'
import { FormFields } from '.'

const FormFieldsMedia = props => {
  // console.log('FormFieldsMedia', props)
  return (
    <FormFields
      grouped={false}
      fieldWidth={props.fieldWidth}
      type={props.type}
      item={props.item}
      gallery={props.gallery}
      schema={props.schema}
      processing={props.processing}
      handleChange={props.handleChange}
      handleNestedItemChange={props.handleNestedItemChange}
      createInputData={item =>
        props.createInputData({
          id: props.context.id,
          ...item,
          collection: props.collection,
        })
      }
    />
  )
}

export default FormFieldsMedia
