import React from 'react'
import classNames from 'classnames'
import { Route, Switch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core'
import { navigation } from '../styles'
import {
  Authenticated,
  Alert,
  Navigation,
  Home,
  Works,
  Work,
  Exhibitions,
  Exhibition,
  Publications,
  Publication,
  Categories,
  Category,
  Projects,
  Project,
  Editions,
  Edition,
  Search,
  Users,
  User,
  NotFound,
  Spinner,
  Preview,
  Previews,
} from '../components'

class App extends React.Component {
  state = { open: false }

  handleDrawerToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <Authenticated>
          <Alert />
          <Spinner />
          <div className={classes.root}>
            <Navigation
              open={this.state.open}
              handleDrawerToggle={this.handleDrawerToggle}
              handleDrawerOpen={this.handleDrawerOpen}
              handleDrawerClose={this.handleDrawerClose}
            />
            <main
              className={classNames(
                classes.content,
                !this.state.open && classes.contentClose,
              )}
            >
              <Paper className={classes.paper}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/works" component={Works} />
                  <Route path="/work/:id" component={Work} />
                  <Route exact path="/exhibitions" component={Exhibitions} />
                  <Route path="/exhibition/:id" component={Exhibition} />
                  <Route exact path="/publications" component={Publications} />
                  <Route path="/publication/:id" component={Publication} />
                  <Route exact path="/categories" component={Categories} />
                  <Route path="/category/:id" component={Category} />
                  <Route exact path="/projects" component={Projects} />
                  <Route path="/project/:id" component={Project} />
                  <Route exact path="/editions" component={Editions} />
                  <Route path="/edition/:id" component={Edition} />
                  <Route exact path="/users" component={Users} />
                  <Route path="/user/:id" component={User} />
                  <Route exact path="/previews" component={Previews} />
                  <Route path="/preview/:id" component={Preview} />
                  <Route path="/search" component={Search} />
                  <Route component={NotFound} />
                </Switch>
              </Paper>
            </main>
          </div>
        </Authenticated>
      </React.Fragment>
    )
  }
}

export default withStyles(navigation)(App)
