import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { clear } from '../reducers/auth'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SearchIcon from '@material-ui/icons/Search'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'

import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InputBase from '@material-ui/core/InputBase'

import { FormSearch } from './Form'
import { pluralize, titleCase } from '../util'
import { collections, interfaces } from '../constants'
import { navigation } from '../styles'

const NavigationListPrimary = props => (
  <List>
    {interfaces.map(item => (
      <ListItem
        button
        disableRipple
        key={`navigation-${item.name}`}
        component={Link}
        className={props.classes.listItem}
        to={item.url || `/${pluralize(item.name)}?per_page=25&page=1`}
      >
        <ListItemIcon className={props.classes.listItemIcon}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography className={props.classes.listItemText}>
              {item.label || titleCase(pluralize(item.name))}
            </Typography>
          }
        />
      </ListItem>
    ))}
  </List>
)

let NavigationListSecondary = props => (
  <ListItem
    button
    disableRipple
    className={props.classes.listItem}
    onClick={() => {
      console.log('click', props.clear)
      props.clear()
    }}
  >
    <ListItemIcon className={props.classes.listItemIcon}>
      <PowerSettingsNewIcon />
    </ListItemIcon>
    <ListItemText
      disableTypography
      primary={
        <Typography className={props.classes.listItemText}>Sign Out</Typography>
      }
    />
  </ListItem>
)

NavigationListSecondary = connect(
  () => ({}),
  dispatch => bindActionCreators({ clear }, dispatch),
)(NavigationListSecondary)

class Navigation extends React.Component {
  render() {
    const {
      classes,
      history,
      open,
      handleDrawerToggle,
      handleDrawerClose,
    } = this.props
    return (
      <React.Fragment>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              onClick={handleDrawerToggle}
              className={classNames(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              className={classes.typographyAppbarHeader}
            >
              Studio Castillo Deball
            </Typography>

            <div className={classes.grow} />

            {/search/.test(window.location.pathname) === false && (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </div>
                <FormSearch>
                  <InputBase
                    name="s"
                    placeholder="Search"
                    style={{ color: 'black' }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                  />
                </FormSearch>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !open && classes.drawerPaperClose,
            ),
          }}
        >
          <div className={classes.toolBar}>
            <IconButton
              className={classes.buttonDrawerClose}
              onClick={handleDrawerClose}
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {collections.map(item => (
              <ListItem
                button
                disableRipple
                key={item.name}
                component={Link}
                to={item.url || `/${pluralize(item.name)}?per_page=25&page=1`}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.listItemText}>
                      {item.label || titleCase(pluralize(item.name))}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>

          <Divider />

          <NavigationListPrimary history={history} classes={classes} />

          <Divider />

          <NavigationListSecondary history={history} classes={classes} />
        </Drawer>
      </React.Fragment>
    )
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withRouter(
  withStyles(navigation, { withTheme: true })(Navigation),
)
