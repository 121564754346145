import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100vh',
    padding: theme.spacing(3),
  },
})

const NotFound = props => {
  let { pathname } = props.history.location
  const { state } = props.history.location
  if (state && state.referrer) {
    ;({ pathname } = state.referrer)
  }
  return (
    <Grid container className={props.classes.root}>
      <Grid item>
        <Typography variant="body1">404 Not Found</Typography>
        {pathname && <Typography variant="body1">{pathname}</Typography>}
      </Grid>
    </Grid>
  )
}

export default withRouter(withStyles(styles)(NotFound))
