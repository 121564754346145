import React from 'react'
import classNames from 'classnames'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { titleCase } from '../../util'
import { Permit, FixedHeader } from '..'

import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import { table } from '../../styles'

const SelectableHeader = props => {
  if (props.header) return props.header
  if (props.withoutHeader) return null
  return props.selectable !== false ? (
    <div className={props.classes.title}>
      {props.numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1">
          {props.numSelected} selected
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle">
          {titleCase(pluralize(props.type))}
        </Typography>
      )}
    </div>
  ) : (
    <Typography variant="h6" id="tableTitle">
      {titleCase(pluralize(props.type))}
    </Typography>
  )
}

const CrudableHeader = props =>
  props.crudable ? (
    <FixedHeader>
      <Permit permissions={774}>
        <Button
          size="small"
          className={props.classes.button}
          variant="contained"
          color="primary"
          onClick={() => props.history.push(`/${props.type}/new`)}
        >
          Add New {titleCase(props.type)}
        </Button>
      </Permit>
    </FixedHeader>
  ) : null

const PreviewableActions = props =>
  props.previewable && props.numSelected > 0 ? (
    <Tooltip title="Add to Preview">
      <IconButton onClick={props.togglePreviewDialog}>
        <LibraryAddIcon />
      </IconButton>
    </Tooltip>
  ) : null

const DownloadableActions = props =>
  props.downloadable && props.numSelected > 0 ? (
    <Tooltip title="Export as PDF">
      <IconButton onClick={props.createExport}>
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  ) : null

const CollectionTableToolbar = props => {
  const {
    type,
    numSelected,
    classes,
    history,
    createExport,
    togglePreviewDialog,
    selectable,
    previewable,
    downloadable,
    crudable,
    absolute,
    withoutHeader,
    header,
    headerStyles,
  } = props

  return (
    <Toolbar
      className={classNames(classes.toolBar, {
        [classes.highlight]: numSelected > 0,
        [classes.absolute]: absolute,
      })}
      style={
        headerStyles
          ? headerStyles
          : withoutHeader
          ? { height: 32, minHeight: 32, top: 16 }
          : {}
      }
    >
      <SelectableHeader
        type={type}
        header={header}
        classes={classes}
        selectable={selectable}
        numSelected={numSelected}
        withoutHeader={withoutHeader}
      />

      <div className={classes.spacer} />

      <div className={classes.actions}>
        {type !== 'preview' && (
          <Permit permissions={774}>
            <React.Fragment>
              <PreviewableActions
                previewable={previewable}
                numSelected={numSelected}
                togglePreviewDialog={togglePreviewDialog}
              />

              <DownloadableActions
                downloadable={downloadable}
                numSelected={numSelected}
                createExport={createExport}
              />
            </React.Fragment>
          </Permit>
        )}
        <CrudableHeader
          crudable={crudable}
          classes={classes}
          history={history}
          type={type}
        />
      </div>
    </Toolbar>
  )
}

CollectionTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
}

export default withStyles(table)(CollectionTableToolbar)
