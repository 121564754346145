import React from 'react'
import flatten from 'lodash/flatten'
import { Permit, Carousel } from '../'
import { FormMediaItemRow } from '.'
import { pluralize, getCollectionNameFromMimeType } from '../../util'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'

const MediaFileInput = props => (
  <input
    hidden
    multiple
    type="file"
    id={`media-${props.gallery.id}`}
    name={props.gallery.id}
    accept="image/*,video/*,audio/*,.pdf,.doc,.docx" // TODO: supported mimes
    disabled={props.processing}
    onChange={props.handleFileChange}
    className={props.classes.input}
  />
)

const AddMediaComponent = props => (
  <React.Fragment>
    <Button
      disableRipple
      size="small"
      component="label"
      variant="contained"
      className={props.classes.button}
    >
      Add Media
      <MediaFileInput {...props} />
    </Button>
  </React.Fragment>
)

const FormMedia = props => {
  const { images, audios, videos, pdfs, documents } = props.gallery
  const media = flatten(
    [images, audios, videos, pdfs, documents].filter(Boolean),
  )

  return (
    <React.Fragment>
      <Toolbar
        style={{ padding: 0, justifyContent: 'flex-end', marginTop: -24 }}
      >
        <Permit
          permissions={764}
          editable={
            <React.Fragment>
              <AddMediaComponent {...props} />
            </React.Fragment>
          }
        />
      </Toolbar>
      <Grid container spacing={1}>
        {media.map(a => {
          const type = getCollectionNameFromMimeType(a.mime_type)
          const collection = pluralize(type)
          return (
            <FormMediaItemRow
              key={`${type}-${props.item.id}-${a.id}`}
              item={a}
              collection={collection}
              type={type}
              context={props.item}
              gallery={props.gallery}
              schema={props.schema}
              classes={props.classes}
              createInputData={props.createInputData}
              processing={props.processing}
              handleChange={props.handleChange}
              handleCopy={props.handleCopy}
              moveItems={props.moveItems}
              handleOpen={props.handleOpen}
              handleDownload={props.handleDownload}
              handleDeleteItem={() =>
                props.handleDeleteItem(a, props.gallery.id)
              }
              handleToggleVisibility={() =>
                props.handleToggleVisibility(a, props.gallery.id)
              }
            />
          )
        })}
      </Grid>
      <Carousel items={media} />
    </React.Fragment>
  )
}

export default FormMedia
