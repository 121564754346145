import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem } from '..'
import {
  FormFieldsSidebarExhibitions,
  FormFieldsSidebarCategories,
  FormFieldsSidebarThumbnail,
  FormFieldsSidebarProjects,
  FormFieldsSidebarTags,
  FormFieldsSidebarMediaTabs,
} from '../Form'

class Work extends React.Component {
  static defaultProps = {
    item: {
      title: '',
      production_date: null,
      medium: '',
      dimensions: null,
      signed: false,
      location_storage: '',
      location_gallery: '',
      location_other: '',

      id: null,
      slug: '',
      description: '',
      notes: '',
      created_date: null,
      destroyed_date: null,
      length: null,
      width: null,
      height: null,
      weight: null,
      sales_status: 'available',
      sale_date: null,
      sale_price: null,
      sale_buyer: '',
      production_status: 'produced',
      measurements: 'total_size',
      production_cost: null,
      categories: [],
      projects: [],
      galleries: [],
      tags: [],
      exhibitions: [],
      sales_collection: '',
      contributors: '',
      language: '',
      publication_type: 'artist_book',
      copies_created: null,
      copies_stored: null,
      collaborators: '',
    },
  }
  render() {
    return (
      <CollectionItem
        type="work"
        sidebarRight={[
          <FormFieldsSidebarThumbnail key="thumbnail" />,
          <FormFieldsSidebarCategories key="categories" />,
          <FormFieldsSidebarProjects key="projects" />,
          <FormFieldsSidebarExhibitions key="exhibitions" />,
          <FormFieldsSidebarTags key="tags" />,
        ]}
        sidebarTabs={<FormFieldsSidebarMediaTabs />}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(Work)
