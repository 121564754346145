import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CollectionTable } from '..'
import { get } from '../../reducers/api'

class Users extends React.Component {
  state = { users: [] }
  componentDidMount() {
    const { search } = window.location
    this.props.get(`/users/${search}`).then(({ data }) => {
      this.setState({ users: data })
    })
  }
  render() {
    const { users } = this.state
    return (
      <CollectionTable
        selectable={false}
        type="user"
        collection={users}
        history={this.props.history}
      />
    )
  }
}

export default connect(
  () => ({}),
  dispatch => bindActionCreators({ get }, dispatch),
)(Users)
