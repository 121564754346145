import React from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Thumbnail } from '..'
import { isNewItem } from '../../util'
import { form } from '../../styles'
import { withStyles } from '@material-ui/core'

const ThumbnailFileInput = props => (
  <input
    hidden
    type="file"
    accept="image/*"
    name="thumbnail"
    id="thumbnail"
    onChange={props.handleThumbnailChange}
  />
)

let NewThumbnailComponent = props => {
  return (
    <React.Fragment>
      <Button
        disableRipple
        size="small"
        component="label"
        variant="contained"
        className={props.classes.button}
      >
        Add Thumbnail
        <ThumbnailFileInput
          handleThumbnailChange={props.handleThumbnailChange}
        />
      </Button>
    </React.Fragment>
  )
}

NewThumbnailComponent = React.memo(NewThumbnailComponent)

const styles = theme => ({
  container: {
    position: 'relative',
    display: 'block',
  },
  item: {
    position: 'absolute',
    top: theme.spacing(-1),
    right: theme.spacing(-1),
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&$visible': {
      opacity: 1,
    },
  },
  visible: {},
  buttonLeft: {
    marginRight: theme.spacing(),
  },
  buttonFab: {
    width: 30,
    height: 30,
    minWidth: 30,
    minHeight: 30,
  },
})

class _EditThumbnailComponent extends React.Component {
  state = { visible: false }
  show = () => this.setState({ visible: true })
  hide = () => this.setState({ visible: false })
  render() {
    const { classes, item } = this.props
    return (
      <Grid
        container
        className={classes.container}
        onMouseEnter={this.show}
        onMouseLeave={this.hide}
      >
        <Grid
          item
          className={classNames(classes.item, {
            [classes.visible]: this.state.visible,
          })}
        >
          <Fab
            disableRipple
            size="small"
            component="label"
            className={classNames(classes.buttonLeft, classes.buttonFab)}
          >
            <ThumbnailFileInput
              handleThumbnailChange={this.props.handleThumbnailChange}
            />
            <AddIcon />
          </Fab>
          <Fab
            disableRipple
            size="small"
            onClick={this.props.handleThumbnailDelete}
            className={classNames(classes.buttonLeft, classes.buttonFab)}
          >
            <DeleteIcon />
          </Fab>
        </Grid>
        <Thumbnail size="large" thumbnail={item.thumbnail} />
      </Grid>
    )
  }
}

const EditThumbnailComponent = withStyles(styles)(_EditThumbnailComponent)

const FormFieldsSidebarThumbnail = props => {
  if (isNewItem(props)) return null
  const { classes } = props

  return (
    <Grid container>
      <Grid item sm={12} className={classes.thumbnailSidebar}>
        {props.item.thumbnail ? (
          <EditThumbnailComponent
            item={props.item}
            handleThumbnailDelete={props.handleThumbnailDelete}
            handleThumbnailChange={props.handleThumbnailChange}
          />
        ) : (
          <NewThumbnailComponent
            classes={props.classes}
            handleThumbnailChange={props.handleThumbnailChange}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default withStyles(form)(FormFieldsSidebarThumbnail)
