import React from 'react'
import { CollectionTable } from '..'

const Previews = props => (
  <CollectionTable
    type="preview"
    selectable={false}
    previewable={false}
    downloadable={false}
    history={props.history}
  />
)

export default Previews
