import React from 'react'
import findIndex from 'lodash/findIndex'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { titleCase } from '../../util'

const SearchFilters = props => {
  return (
    <Grid item sm={6}>
      {Object.entries(props.filterMappings).map(([collection, entries]) => (
        <React.Fragment key={collection}>
          <Typography
            color="inherit"
            variant="subtitle1"
            style={{ margin: '12px 0' }}
          >
            {titleCase(collection)}
          </Typography>
          {Array.from(entries, ([handle, { id, name, key }]) => (
            <FormControlLabel
              key={`${collection}-${id}`}
              name={handle}
              label={titleCase(name)}
              control={
                <Checkbox
                  style={{ padding: 8 }}
                  checked={findIndex(props.filters, { [key]: id }) >= 0}
                  disableRipple={true}
                  disabled={props.processing}
                  onChange={props.onChange(collection)(id)}
                />
              }
            />
          ))}
        </React.Fragment>
      ))}
    </Grid>
  )
}

export default SearchFilters
