import React from 'react'
import { Permit } from '..'
import { Wysiwyg } from '.'
import TextField from '@material-ui/core/TextField'

class FormTextField extends React.Component {
  constructor(props) {
    super(props)
    this.handleImmediateChange = this.handleImmediateChange.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.item.value !== this.props.item.value) {
      return true
    }

    return false
  }

  handleImmediateChange({ html: value }) {
    const { item, gallery } = this.props
    this.props.handleChange({ proxy: { ...item, value } }, gallery)
  }

  render() {
    return (
      <Permit
        permissions={764}
        editable={
          <Wysiwyg
            item={this.props.item}
            initialEditorState={this.props.item.value}
            onChange={this.handleImmediateChange}
            readOnly={this.props.item.readonly}
            required={this.props.item.required}
          />
        }
        viewable={
          <TextField
            InputProps={{ readOnly: true }}
            multiline
            rowsMax="6"
            className={this.props.classes.textarea}
            value={this.props.item.value}
            required={this.props.item.required}
          />
        }
      />
    )
  }
}

export default FormTextField
