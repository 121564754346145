import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { stableSort, getSorting, pluralize } from '../../util'
import { collectionsAndInterfaces } from '../../constants'
import { bindActionCreators } from 'redux'
import { get, post, patch } from '../../reducers/api'
import { freeze, unfreeze } from '../../reducers/spinner'
import { table } from '../../styles'
import {
  CollectionTableHead,
  CollectionTableToolbar,
  CollectionTablePaginationActions,
  Permit,
} from '..'
import selectable from '../selectable'
import previewable from '../previewable'
import exportable from '../exportable'

class CollectionTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'id',
    collection: [],
    page: 1,
    rowsPerPage: 25,
    total: 0,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { collection, total } = nextProps

    if (collection && collection.length !== prevState.collection.length) {
      return { ...prevState, collection }
    }

    if (total && total !== prevState.total) {
      return { ...prevState, total }
    }

    return null
  }

  componentDidMount() {
    let { rowsPerPage, page } = this.state
    const params = new window.URLSearchParams(window.location.search)
    const _page = Number(params.get('page'))

    if (_page && _page !== page) page = _page

    const _rowsPerPage = Number(params.get('per_page'))
    if (_rowsPerPage && _rowsPerPage !== rowsPerPage) rowsPerPage = _rowsPerPage

    params.set('page', page)
    params.set('per_page', rowsPerPage)

    this.paginate(page, params, 'replace')
  }

  handleRequestSort = (e, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handlePageChange = (e, zeroBasedPageIndex) => {
    const { page } = this.state
    const nextPage = zeroBasedPageIndex + 1
    const params = new window.URLSearchParams(window.location.search)
    const key = 'page'

    if (page === nextPage) return

    params.set(key, nextPage)
    this.paginate(nextPage, params)
  }

  paginate = (page, params, updateMethod = 'push') => {
    this.props.freeze()

    const { rowsPerPage } = this.state
    const { pathname } = window.location
    const { type } = this.props
    const pluralType = pluralize(type)
    const search = `?${params.toString()}`

    this.props.history[updateMethod]({ pathname, search })

    this.props.get(`/${pluralType}/${search}`).then(resp => {
      const { data } = resp

      let { total } = resp
      total = Number(total)

      let collection = [...this.state.collection]

      // fill with placeholders
      if (collection.length !== total) collection.length = total
      for (let i = 0; i < collection.length; i++) {
        collection[i] = collection[i] ? collection[i] : {}
      }

      collection.splice((page - 1) * rowsPerPage, data.length, ...data)

      this.setState(
        {
          page,
          rowsPerPage,
          collection,
          total,
        },
        () => this.props.unfreeze(),
      )
    })
  }

  handleChangeRowsPerPage = e => {
    const rowsPerPage = Number(e.target.value)
    this.setState({ rowsPerPage }, () => {
      const params = new window.URLSearchParams(window.location.search)
      const key = 'per_page'

      if (Number(params.get(key)) === rowsPerPage) return

      params.set(key, rowsPerPage)
      const { pathname } = window.location
      const search = `?${params.toString()}`

      this.props.history.push({ pathname, search })
    })
  }

  render() {
    const {
      type,
      history,
      selected,
      classes,
      selectable,
      previewable,
    } = this.props

    const { collection, order, orderBy, rowsPerPage, page, total } = this.state
    const { rows } = find(collectionsAndInterfaces, { name: type })

    const zeroBasedPageIndex = page > 0 ? page - 1 : page

    return (
      <React.Fragment>
        <CollectionTableToolbar
          type={type}
          numSelected={selected.length}
          collection={collection}
          history={history}
          createExport={this.props.createExport}
          togglePreviewDialog={this.props.togglePreviewDialog}
          previewable={type !== 'project'}
          selectable={selectable && type !== 'project'}
          downloadable={type !== 'project'}
          crudable={true}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.tableFixed}>
            <CollectionTableHead
              numSelected={selected.length}
              rows={rows}
              rowCount={collection.length}
              type={type}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.props.handleSelectAllClick(collection)}
              onRequestSort={this.handleRequestSort}
              classes={classes}
              selectable={selectable && type !== 'project'}
              previewable={previewable && type !== 'project'}
            />
            <TableBody>
              {stableSort(collection, getSorting(order, orderBy))
                .slice(
                  zeroBasedPageIndex * rowsPerPage,
                  zeroBasedPageIndex * rowsPerPage + rowsPerPage,
                )
                .map(item => {
                  const isSelected = this.props.isSelected(item)
                  return (
                    <TableRow
                      hover
                      style={{ cursor: 'pointer' }}
                      key={item.id}
                      tabIndex={-1}
                      role="checkbox"
                      aria-checked={isSelected}
                      selected={isSelected}
                    >
                      {selectable !== false && type !== 'project' && (
                        <Permit permissions={774}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={e => this.props.addToSelected(e, item)}
                              checked={isSelected}
                            />
                          </TableCell>
                        </Permit>
                      )}
                      {rows.map((row, i) => (
                        <TableCell
                          key={i}
                          className={classes.tableCell}
                          style={row.cellStyle ? row.cellStyle : {}}
                          onClick={() => history.push(`/${type}/${item.id}`)}
                        >
                          {row.transform
                            ? row.transform(this, item)(item[row.id])
                            : item[row.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[]} // disable
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={zeroBasedPageIndex}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={CollectionTablePaginationActions}
          />
        </div>
      </React.Fragment>
    )
  }
}

CollectionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default connect(
  () => ({}),
  dispatch =>
    bindActionCreators({ get, post, patch, freeze, unfreeze }, dispatch),
)(selectable(previewable(exportable(withStyles(table)(CollectionTable)))))
