import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core'

const styles = () => ({
  backDrop: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: 10002,
    background: 'rgba(255,255,255,0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const Spinner = props =>
  props.spinner.visible ? (
    <div className={props.classes.backDrop}>
      <CircularProgress />
    </div>
  ) : null

export default connect(
  ({ spinner }) => ({ spinner }),
  () => ({}),
)(withStyles(styles)(Spinner))
