import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem } from '..'
import {
  FormFieldsSidebarExhibitions,
  FormFieldsSidebarCategories,
  FormFieldsSidebarProjects,
  FormFieldsSidebarThumbnail,
  FormFieldsSidebarMediaTabs,
  FormFieldsSidebarTags,
} from '../Form'

class Publication extends React.Component {
  static defaultProps = {
    item: {
      title: null,
      sub_title: null,
      author_primary: '',
      author_secondary: '',
      translator: '',
      contributors: '',
      editor: '',
      publisher: '',
      dimensions: null,
      height: null,
      length: null,
      width: null,
      weight: null,
      uuid_type: 'isbn',
      uuid: null,
      pages: null,
      sale_price: null,
      sales_status: 'available',
      publication_type: 'artist_book',
      copies_created: 0,
      copies_stored: 0,
      notes: null,

      copyright: '',
      created_at: null, // TODO
      created_date: null, // TODO
      description: '',
      destroyed_date: null, // TODO
      edition: null,
      id: null,
      slug: null,
      updated_at: null,
      year: null,
      categories: [],
      projects: [],
      exhibitions: [],
      galleries: [],
      tags: [],
    },
  }
  render() {
    return (
      <CollectionItem
        type="publication"
        sidebarRight={[
          <FormFieldsSidebarThumbnail key="thumbnail" />,
          <FormFieldsSidebarProjects key="projects" />,
          <FormFieldsSidebarExhibitions key="exhibitions" />,
          <FormFieldsSidebarCategories key="categories" />,
          <FormFieldsSidebarTags key="tags" />,
        ]}
        sidebarTabs={<FormFieldsSidebarMediaTabs />}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(Publication)
