import React from 'react'
import classNames from 'classnames'
import isArray from 'lodash/isArray'
import has from 'lodash/has'
import isNull from 'lodash/isNull'

import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import IconChevronRight from '@material-ui/icons/ChevronRight'
import IconChevronLeft from '@material-ui/icons/ChevronLeft'

import { withRouter } from 'react-router-dom'
import { form } from '../../styles'
import { FormFields } from '.'
import { Permit, FixedHeader } from '..'
import { isNewItem, getTitle, getDimensions, datetimeToYear } from '../../util'

const hasSidebarRight = props => {
  if (isArray(props.sidebarRight)) return props.sidebarRight.length > 0
  return Boolean(props.sidebarRight)
}

const SidebarRight = props => {
  if (!props.sidebarRight) return null
  const sidebarRight = isArray(props.sidebarRight)
    ? [...props.sidebarRight]
    : [props.sidebarRight]

  return (
    <Grid item sm={4}>
      {sidebarRight.map((c, i) => (
        <Paper key={i} className={props.classes.paperRight}>
          <Grid container>
            <Grid item sm={12}>
              {React.cloneElement(c, { ...props })}
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Grid>
  )
}

const SidebarBottom = props => {
  if (!props.sidebarBottom) return null
  const sidebarBottom = isArray(props.sidebarBottom)
    ? [...props.sidebarBottom]
    : [props.sidebarBottom]
  const { classes, ...rest } = props

  return (
    <Paper className={classes.paper} style={{ width: '100%' }}>
      <Grid container>
        <Grid item sm={12}>
          <Grid container spacing={0}>
            {sidebarBottom.map((c, i) => {
              return (
                <Grid key={i} item sm={12}>
                  {React.cloneElement(c, { ...rest, ...c.props })}
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const FormHeaderActions = props => {
  const { classes, item, type } = props
  return (
    <React.Fragment>
      <div className={classes.spacer} />
      <FixedHeader>
        {has(item, 'next') && has(item, 'prev') && (
          <div>
            <IconButton
              disabled={isNull(item.prev)}
              style={{ padding: 6 }}
              onClick={() => props.history.push(`/${type}/${item.prev}`)}
            >
              <IconChevronLeft fontSize="small" />
            </IconButton>
            <IconButton
              disabled={isNull(props.item.next)}
              style={{ padding: 6 }}
              onClick={() => props.history.push(`/${type}/${item.next}`)}
            >
              <IconChevronRight fontSize="small" />
            </IconButton>
          </div>
        )}
        <div className={classes.actions}>
          <Permit
            permissions={744}
            editable={
              isNewItem(props) ? null : (
                <Button
                  size="small"
                  className={classNames(classes.button, classes.error)}
                  variant="contained"
                  onClick={props.handleDelete}
                >
                  Delete
                </Button>
              )
            }
          />
          <Permit
            permissions={774}
            editable={
              <Button
                size="small"
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            }
          />
        </div>
      </FixedHeader>
    </React.Fragment>
  )
}

const Header = props => {
  const title = getTitle(props)
  const dimensions = getDimensions(props)
  const year = datetimeToYear(
    String(props.production_date || props.start_date || props.year),
  )
  const { medium } = props

  return (
    <Typography variant="h6" style={{ paddingTop: 24 }}>
      {title}
      {isNaN(year) === false && (
        <React.Fragment>
          <br />
          {year}
        </React.Fragment>
      )}
      {medium && (
        <React.Fragment>
          <br />
          {medium}
        </React.Fragment>
      )}
      {dimensions && (
        <React.Fragment>
          <br />
          {dimensions}
        </React.Fragment>
      )}
    </Typography>
  )
}

const FormHeader = props => {
  const { classes, item } = props
  return (
    <React.Fragment>
      <Grid item sm={8}>
        <Toolbar className={classes.toolBar} style={{ minHeight: 56 }}>
          <div className={classes.title}>
            <Header {...item} />
          </div>
          <FormHeaderActions {...props} />
        </Toolbar>
      </Grid>
    </React.Fragment>
  )
}

const FormMain = props => {
  const { classes, item, tab } = props
  return (
    <Grid item sm={hasSidebarRight(props) ? 8 : 12}>
      <Paper className={classes.paper}>
        <AppBar className={classes.appBar} position="static">
          <Tabs
            value={tab}
            onChange={props.handleTabChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
              flexContainer: classes.tabsFlexContainer,
            }}
          >
            <Tab
              disableRipple
              label="Metadata"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            {item.galleries.map((a, i) => (
              <Tab
                key={i}
                disableRipple
                label={a.title}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            ))}
          </Tabs>
        </AppBar>

        {tab === 0 && (
          <FormFields
            grouped={true}
            type={props.type}
            item={item}
            schema={props.schema}
            createInputData={props.createInputData}
            processing={props.processing}
            handleChange={props.handleChange}
            handleNestedItemChange={props.handleNestedItemChange}
            handleCheckboxChange={props.handleCheckboxChange}
          />
        )}

        {item.galleries.map((gallery, i) => (
          <React.Fragment key={gallery.id}>
            {tab === i + 1 && // TODO: start at 1 to allow for metadata above; fixme
              React.cloneElement(props.sidebarTabs, {
                gallery,
                ...props,
              })}
          </React.Fragment>
        ))}
      </Paper>
    </Grid>
  )
}

const FormContainer = props => {
  return (
    <Grid container>
      <FormHeader {...props} />
      <FormMain {...props} />
      <SidebarRight {...props} />
      <SidebarBottom {...props} />
    </Grid>
  )
}

class Form extends React.Component {
  state = { tab: 0 }

  handleTabChange = (_, tab) => {
    this.setState({ tab })
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <FormContainer
          tab={this.state.tab}
          handleTabChange={this.handleTabChange}
          {...this.props}
        />
      </form>
    )
  }
}

export default withRouter(withStyles(form)(Form))
