import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import findIndex from 'lodash/findIndex'
import IconButton from '@material-ui/core/IconButton'
import IconChevronRight from '@material-ui/icons/ChevronRight'
import IconChevronLeft from '@material-ui/icons/ChevronLeft'
import IconClose from '@material-ui/icons/Close'

import { close } from '../reducers/carousel'
import { getCollectionNameFromMimeType } from '../util'

// Global jQuery to resolve owl-carousel loading error since Webpack's
// ProvidePlugin is inaccessible in CRA.
// https://github.com/facebook/create-react-app/issues/4281
import '../globals'

// Then import owl-carousel
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

const carouselStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 10001,
}

const owlStyles = {
  background: 'rgba(0,0,0,0.5)',
  width: '100vw',
  height: '100vh',
}

const itemStyles = {
  width: '100vw',
  height: 'calc(100vh - 50px)',
}

const mediaStyles = {
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  paddingTop: 30,
}

const buttonStyles = {
  zIndex: 99999,
  position: 'fixed',
  backgroundColor: 'white',
}

const mediaProps = {
  preload: 'metadata',
  controls: 'controls',
}

let owlCarousel = null

const CarouselImage = props => (
  <img src={props.urls.large} alt={props.alt} style={mediaStyles} />
)

const CarouselAudio = props => (
  <audio src={props.urls.source} style={mediaStyles} {...mediaProps} />
)

const CarouselVideo = props => (
  <video src={props.urls.source} style={mediaStyles} {...mediaProps} />
)

const CarouselPDF = props => (
  <img src={props.urls.preview} alt={props.alt} style={mediaStyles} />
)

const CarouselPlaceholder = props => (
  <img
    src={props.urls.preview || ''}
    alt={props.mime_type}
    style={mediaStyles}
  />
)

const handleKeydown = e => {
  if (!e || !e.which || !owlCarousel) return
  if (e.which === 37) owlCarousel.prev()
  if (e.which === 39) owlCarousel.next()
  if (e.which === 27) owlCarousel.props.close()
}

const Carousel = props => {
  return (
    <div style={props.carousel.visible ? carouselStyles : { display: 'none' }}>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '.owl-theme .owl-nav.disabled + .owl-dots { margin-top: 15px; }',
        }}
      />

      <OwlCarousel
        className="owl-theme"
        loop
        items={1}
        mouseDrag={false}
        center={true}
        style={owlStyles}
        onInitialized={function() {
          if (!props.carousel.visible || props.carousel.id < 1) return
          owlCarousel = this
          owlCarousel.props = { ...props }

          const { id, mime_type } = props.carousel
          const index = findIndex(props.items, { id, mime_type })

          owlCarousel.to(index, 0)

          document.removeEventListener('keydown', handleKeydown)
          document.addEventListener('keydown', handleKeydown)
        }}
      >
        {props.items.map((a, i) => {
          const type = getCollectionNameFromMimeType(a.mime_type)
          return (
            <div key={i} className="item" style={itemStyles}>
              {type === 'image' ? (
                <CarouselImage {...a} />
              ) : type === 'video' ? (
                <CarouselVideo {...a} />
              ) : type === 'audio' ? (
                <CarouselAudio {...a} />
              ) : type === 'pdf' ? (
                <CarouselPDF {...a} />
              ) : (
                <CarouselPlaceholder {...a} />
              )}
            </div>
          )
        })}
      </OwlCarousel>

      <IconButton
        style={{ ...buttonStyles, top: 30, right: 30 }}
        onClick={props.close}
      >
        <IconClose fontSize="small" />
      </IconButton>

      <IconButton
        style={{
          ...buttonStyles,
          top: '50%',
          left: 30,
          transform: 'translateY(-50%)',
        }}
        onClick={() => owlCarousel.prev()}
      >
        <IconChevronLeft fontSize="small" />
      </IconButton>

      <IconButton
        style={{
          ...buttonStyles,
          top: '50%',
          right: 30,
          transform: 'translateY(-50%)',
        }}
        onClick={() => owlCarousel.next()}
      >
        <IconChevronRight fontSize="small" />
      </IconButton>
    </div>
  )
}

const mapStateToProps = ({ carousel }) => ({
  carousel,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      close,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(Carousel)
