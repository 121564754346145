import React from 'react'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Typography from '@material-ui/core/Typography'
import { Thumbnail } from '..'
import { FormFields } from '.'
import { withStyles } from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'

import { table } from '../../styles'

const rows = [
  { id: 'sku', label: 'SKU' },
  { id: 'thumbnail', label: 'Thumbnail' },
  { id: 'edition_number', label: 'Edition Number' },
  { id: 'artist_proof', label: 'Artist Proof' },
]

class Impression extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.open !== this.props.entry.id &&
      nextProps.open !== this.props.entry.id
    ) {
      return false
    }

    return true
  }

  render() {
    return (
      <React.Fragment key={this.props.entry.id}>
        <TableRow
          hover
          style={{ cursor: 'pointer' }}
          onClick={this.props.openDialog(this.props.entry.id)}
        >
          <TableCell className={this.props.classes.tableCell}>
            {this.props.entry.sku}
          </TableCell>
          <TableCell className={this.props.classes.tableCell}>
            <Thumbnail
              size="small"
              thumbnail={this.props.entry.thumbnail || ''}
            />
          </TableCell>
          <TableCell className={this.props.classes.tableCell}>
            {this.props.entry.artist_proof
              ? `AP-${this.props.entry.edition_number}`
              : this.props.entry.edition_number}
          </TableCell>
          <TableCell className={this.props.classes.tableCell}>
            {this.props.entry.artist_proof ? <CheckCircleOutlineIcon /> : ''}
          </TableCell>
        </TableRow>

        <Dialog
          maxWidth="sm"
          open={this.props.open === this.props.entry.id}
          PaperProps={{
            style: {
              width: '100vw',
              height: '100vh',
              borderRadius: 0,
              overflow: 'auto',
            },
          }}
        >
          <DialogContent>
            <Grid container>
              <Grid item sm={12}>
                <FormFields
                  grouped={false}
                  fieldWidth={12}
                  type="impression"
                  item={this.props.entry}
                  schema={this.props.schema}
                  createInputData={this.props.createInputData}
                  processing={this.props.processing}
                  handleChange={e =>
                    this.props.handleNestedItemChange(
                      {
                        proxy: {
                          id: this.props.entry.id,
                          name: e.proxy.name,
                          value: e.proxy.value,
                        },
                      },
                      'impressions',
                    )
                  }
                  handleCheckboxChange={e =>
                    this.props.handleNestedItemChange(
                      {
                        proxy: {
                          id: this.props.entry.id,
                          name: e.currentTarget.name,
                        },
                      },
                      'impressions',
                    )
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

class FormFieldsSidebarImpressions extends React.Component {
  state = { open: null }

  openDialog = id => () => this.setState({ open: id })

  closeDialog = () => this.setState({ open: null })

  render() {
    const { item, classes } = this.props
    return (
      <React.Fragment>
        <Toolbar style={{ padding: 0, marginBottom: -16 }}>
          <Typography className={classes.formLabelTypography} noWrap>
            Impressions
          </Typography>
        </Toolbar>

        <Table className={classes.tableFixed}>
          <TableHead>
            <TableRow>
              {rows.map(row => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {item.impressions.map(entry => (
              <Impression
                key={entry.id}
                entry={entry}
                classes={classes}
                open={this.state.open}
                schema={this.props.schema}
                createInputData={this.props.createInputData}
                processing={this.props.processing}
                handleNestedItemChange={this.props.handleNestedItemChange}
                closeDialog={this.closeDialog}
                openDialog={this.openDialog}
              />
            ))}
          </TableBody>
        </Table>
      </React.Fragment>
    )
  }
}

export default withStyles(table)(FormFieldsSidebarImpressions)
