import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CollectionItemFields, CollectionTableToolbar } from '..'
import { get, patch, post, del } from '../../reducers/api'
import { show } from '../../reducers/alert'
import { open } from '../../reducers/carousel'
import { freeze, unfreeze } from '../../reducers/spinner'
import collectionItemWrapper from './collectionItemWrapper'

class CollectionItem extends React.Component {
  componentDidMount() {
    this.initialize()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.api.status === 404) {
      this.props.history.push('/404', { referrer: this.props.history.location })
    }

    // TODO redux? This is just handling 'page changes' (the user clicking prev
    // or next collection item buttons) within collection item
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.initialize()
    }
  }

  initialize() {
    this.props.freeze()
    this.props.getItem().then(() => {
      this.props.unfreeze()

      this.props.getCategories()
      this.props.getProjects()
      this.props.getExhibitions() // TODO: only necessary for works rn
    })
  }

  render() {
    const { type, history } = this.props
    const downloadable = {
      project: true,
      exhibition: true,
      publication: true,
      work: true,
      edition: true,
      preview: true,
      related: true,
    }

    return (
      <React.Fragment>
        <CollectionTableToolbar
          type={type}
          history={history}
          createExport={this.props.createExport}
          numSelected={1}
          absolute={true}
          crudable={false}
          selectable={false}
          previewable={false}
          withoutHeader={true}
          downloadable={downloadable[type]}
          headerStyles={{
            top: 16,
            height: 32,
            minHeight: 32,
            paddingRight: 0,
          }}
        />
        <CollectionItemFields
          type={this.props.type}
          handleSubmit={
            this.props.item.id
              ? this.props.handleUpdate
              : this.props.handleCreate
          }
          handleDelete={this.props.handleDelete}
          handleChange={this.props.handleChange}
          handleNestedItemChange={this.props.handleNestedItemChange}
          handleFileChange={this.props.handleFileChange}
          handleCheckboxChange={this.props.handleCheckboxChange}
          handleThumbnailChange={this.props.handleThumbnailChange}
          handleThumbnailDelete={this.props.handleThumbnailDelete}
          handleDeleteItem={this.props.handleDeleteItem}
          handleToggleVisibility={this.props.handleToggleVisibility}
          handleDeleteChip={this.props.handleDeleteChip}
          handleAddChip={this.props.handleAddChip}
          handleRemoveItem={this.props.handleRemoveItem}
          handleCopy={this.props.handleCopy}
          moveItems={this.props.moveItems}
          handleOpen={this.props.handleOpen}
          handleDownload={this.props.handleDownload}
          item={this.props.item}
          schema={this.props.schema}
          categories={this.props.categories}
          tags={this.props.tags}
          projects={this.props.projects}
          exhibitions={this.props.exhibitions}
          impressions={this.props.impressions}
          previews={this.props.previews}
          processing={this.props.processing}
          sidebarTop={this.props.sidebarTop}
          sidebarRight={this.props.sidebarRight}
          sidebarBottom={this.props.sidebarBottom}
          sidebarLeft={this.props.sidebarLeft}
          sidebarTabs={this.props.sidebarTabs}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ api, auth }) => ({
  api,
  auth,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      get,
      patch,
      post,
      del,
      show,
      open,
      freeze,
      unfreeze,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(collectionItemWrapper(CollectionItem))
