import React from 'react'
import { FormFieldsTags } from '.'

const FormFieldsSidebarTags = props => {
  return (
    <FormFieldsTags
      item={props.item}
      tags={props.item.tags}
      schema={props.schema}
      classes={props.classes}
      createInputData={props.createInputData}
      processing={props.processing}
      handleCheckboxChange={props.handleCheckboxChange}
      handleDeleteChip={props.handleDeleteChip}
      handleAddChip={props.handleAddChip}
      handleRemoveItem={props.handleRemoveItem}
    />
  )
}

export default FormFieldsSidebarTags
