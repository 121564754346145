import React from 'react'
import { createMultipleSelectOptions } from '../../util'
import { FormFieldsCollectionSelect } from '.'

const FormFieldsSidebarCategories = props => {
  return (
    <FormFieldsCollectionSelect
      collection="categories"
      options={createMultipleSelectOptions(props, 'categories')}
      item={props.item}
      type={props.type}
      categories={props.categories}
      schema={props.schema}
      classes={props.classes}
      createInputData={props.createInputData}
      processing={props.processing}
      handleChange={props.handleChange}
    />
  )
}

export default FormFieldsSidebarCategories
