import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem, Categorizations } from '..'

class Category extends React.Component {
  static defaultProps = {
    item: {
      id: null,
      slug: '',
      name: '',
      display_name: '',
      items: [],
    },
  }

  render() {
    return (
      <CollectionItem
        type="category"
        sidebarBottom={<Categorizations />}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(Category)
