/* eslint-disable react/display-name */

import React from 'react'
import { datetimeToYear } from '../util'
import { Thumbnail } from '../components'
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda'
import CategoryIcon from '@material-ui/icons/Category'
import PreviewIcon from '@material-ui/icons/WebAsset'
import DescriptionIcon from '@material-ui/icons/Description'
import ExploreIcon from '@material-ui/icons/Explore'
import PeopleIcon from '@material-ui/icons/People'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import SearchIcon from '@material-ui/icons/Search'

const inlineButtonStyles = {
  padding: 8,
  float: 'right',
}

const workCollectionRows = [
  { id: 'sku', numeric: false, disablePadding: false, label: 'SKU' },
  {
    id: 'thumbnail',
    numeric: false,
    disablePadding: false,
    label: 'Thumbnail',
    transform: () => thumbnail => (
      <Thumbnail size="small" thumbnail={thumbnail} />
    ),
  },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  {
    id: 'production_date',
    numeric: false,
    disablePadding: false,
    label: 'Production Date',
    transform: () => year => year || '',
  },
  {
    id: 'medium',
    numeric: false,
    disablePadding: false,
    label: 'Medium',
  },
  {
    id: 'dimensions',
    numeric: false,
    disablePadding: false,
    label: 'Dimensions',
  },
  {
    id: 'sales_status',
    numeric: false,
    disablePadding: false,
    label: 'Sales Status',
    transform: () => str =>
      str ? `${str[0].toUpperCase()}${str.slice(1)}` : '',
  },
]

const publicationCollectionRows = [
  { id: 'sku', numeric: false, disablePadding: false, label: 'SKU' },
  {
    id: 'thumbnail',
    numeric: false,
    disablePadding: false,
    label: 'Thumbnail',
    transform: () => thumbnail => (
      <Thumbnail size="small" thumbnail={thumbnail} />
    ),
  },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  {
    id: 'author_primary',
    numeric: false,
    disablePadding: false,
    label: 'Author',
  },
  {
    id: 'publisher',
    numeric: false,
    disablePadding: false,
    label: 'Publisher',
  },
  { id: 'year', numeric: false, disablePadding: false, label: 'Year' },
]

const exhibitionCollectionRows = [
  { id: 'sku', numeric: false, disablePadding: false, label: 'SKU' },
  {
    id: 'thumbnail',
    numeric: false,
    disablePadding: false,
    label: 'Thumbnail',
    transform: () => thumbnail => (
      <Thumbnail size="small" thumbnail={thumbnail} />
    ),
  },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
    transform: () => datetimeToYear,
  },
]

const categoryCollectionRows = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'display_name', numeric: false, disablePadding: false, label: 'Name' },
]

const previewCollectionRows = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'count', numeric: false, disablePadding: false, label: 'Entries' },
  {
    id: 'object_url',
    numeric: false,
    disablePadding: false,
    label: '',
    transform: (context, props) => hash => (
      <React.Fragment>
        <IconButton
          color="inherit"
          style={inlineButtonStyles}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            window.open(
              `${process.env.REACT_APP_BASE_URL.replace(
                /\/$/,
                '',
              )}/previews/${hash}`,
            )
          }}
        >
          <OpenInNewIcon />
        </IconButton>
        <IconButton
          color="inherit"
          style={inlineButtonStyles}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            context.props.createExport(props.items)
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
      </React.Fragment>
    ),
  },
]

const projectCollectionRows = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  {
    id: 'thumbnail',
    numeric: false,
    disablePadding: false,
    label: 'Thumbnail',
    transform: () => thumbnail => (
      <Thumbnail size="small" thumbnail={thumbnail} />
    ),
  },
  { id: 'display_name', numeric: false, disablePadding: false, label: 'Name' },
]

const editionCollectionRows = [
  { id: 'sku', numeric: false, disablePadding: false, label: 'SKU' },
  {
    id: 'thumbnail',
    numeric: false,
    disablePadding: false,
    label: 'Thumbnail',
    transform: () => thumbnail => (
      <Thumbnail size="small" thumbnail={thumbnail} />
    ),
  },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
]

const userInterfaceRows = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'role', numeric: false, disablePadding: false, label: 'Role' },
]

export const collections = [
  { name: 'work', icon: <ViewAgendaIcon />, rows: [...workCollectionRows] },
  {
    name: 'exhibition',
    icon: <ExploreIcon />,
    rows: [...exhibitionCollectionRows],
  },
  {
    name: 'publication',
    icon: <DescriptionIcon />,
    rows: [...publicationCollectionRows],
  },
  {
    name: 'edition',
    icon: <ViewColumnIcon />,
    rows: [...editionCollectionRows],
  },
  {
    name: 'project',
    icon: <ChangeHistoryIcon />,
    rows: [...projectCollectionRows],
  },
  {
    name: 'category',
    icon: <CategoryIcon />,
    rows: [...categoryCollectionRows],
  },
  {
    name: 'preview',
    icon: <PreviewIcon />,
    rows: [...previewCollectionRows],
  },
]

export const interfaces = [
  {
    name: 'search',
    icon: <SearchIcon />,
    url: '/search',
    label: 'Search',
    rows: [
      { id: 'id', label: 'ID' },
      { id: 'thumbnail', label: 'Thumbnail' },
      { id: 'title', label: 'Title' },
      { id: 'sku', label: 'SKU' },
      { id: '_date', label: 'Date' },
      { id: 'type', label: 'Type' },
    ],
  },
  { name: 'user', icon: <PeopleIcon />, rows: [...userInterfaceRows] },
]

export const collectionsAndInterfaces = [...collections, ...interfaces]

export const mediaTypes = ['images', 'videos', 'audios', 'pdfs', 'documents']

export const roles = {
  super_admin: 0,
  admin: 0,
  editor: 1,
  guest: 2,
}
