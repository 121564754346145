// https://codesandbox.io/s/l7v0qz869z
// http://clauderic.github.io/react-sortable-hoc/#/basic-configuration/basic-usage?_k=b5rzgy
// https://stackoverflow.com/questions/38457902/how-to-drag-n-drop-tableheadercolumn-from-material-ui-with-react-dnd
// http://jakeboxer.com/blog/2011/10/09/using-acts-as-list-in-a-polymorphic-scope/

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import DragHandleIcon from '@material-ui/icons/DragHandle'

import { titleCase } from '../../util'
import { table } from '../../styles'
import { get, patch } from '../../reducers/api'
import { freeze, unfreeze } from '../../reducers/spinner'
import { Thumbnail, CollectionTableToolbar } from '..'

let CollectionTableHead = props => {
  const { rows, classes } = props

  return (
    <TableHead>
      <TableRow className={classes.flexTableRow}>
        {rows.map((row, i) => {
          return (
            <TableCell
              key={`${row.id}-${i}`}
              align={row.numeric ? 'right' : 'left'}
              className={classes.flexTableCell}
            >
              {row.label}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

CollectionTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
}

CollectionTableHead = withStyles(table)(CollectionTableHead)

const toolbarStyle = {
  padding: 0,
  height: 32,
  minHeight: 32,
  marginTop: 8,
  marginBottom: 8,
}

const inlineButtonStyle = {
  padding: 8,
  float: 'right',
}

const DragHandle = SortableHandle(() => <DragHandleIcon />)

const SortableTableRow = SortableElement(
  ({ index, classes, item, inlineButtonStyle, handleRemoveItem }) => (
    <TableRow hover index={index} className={classes.flexTableRow}>
      <TableCell className={classes.flexTableCell}>{item.id}</TableCell>
      <TableCell className={classes.flexTableCell}>{item.sku}</TableCell>
      <TableCell className={classes.flexTableCell}>
        <Thumbnail size="small" thumbnail={item.thumbnail} />
      </TableCell>
      <TableCell className={classes.flexTableCell}>{item.title}</TableCell>
      <TableCell className={classes.flexTableCell}>
        {titleCase(item.type)}
      </TableCell>

      <TableCell
        className={classes.flexTableCell}
        style={{ justifyContent: 'flex-end' }}
      >
        {/* Drag */}
        <IconButton style={inlineButtonStyle}>
          <DragHandle style={inlineButtonStyle} />
        </IconButton>

        {/* Remove */}
        <IconButton
          style={inlineButtonStyle}
          onClick={e => handleRemoveItem(e, item)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ),
)

const SortableTable = SortableContainer(props => (
  <TableBody>
    {props.items.map((item, index) => (
      <SortableTableRow
        inlineButtonStyle={props.inlineButtonStyle}
        handleRemoveItem={props.handleRemoveItem}
        classes={props.classes}
        index={index}
        item={item}
        key={index}
      />
    ))}
  </TableBody>
))

class PreviewsTable extends React.Component {
  static defaultProps = { item: { items: [] } }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.moveItems(oldIndex, newIndex)
  }

  render() {
    const rows = [
      { id: 'id', label: 'ID' },
      { id: 'sku', label: 'SKU' },
      { id: 'thumbnail', label: 'Thumbnail' },
      { id: 'title', label: 'Title' },
      { id: 'type', label: 'Type' },
      { id: 'actions', label: '', numeric: true },
    ]

    const { type, history, classes, item } = this.props
    const { items } = item

    if (!items.length) return null

    return (
      <React.Fragment>
        <CollectionTableToolbar
          type={type}
          history={history}
          togglePreviewDialog={this.props.togglePreviewDialog}
          absolute={false}
          crudable={false}
          downloadable={false}
          withoutHeader={true}
          headerStyles={toolbarStyle}
          header={
            <Toolbar style={toolbarStyle}>
              <Typography className={classes.formLabelTypography} noWrap>
                Entries
              </Typography>
            </Toolbar>
          }
        />

        <Grid container spacing={0}>
          <Grid item sm={12}>
            <Table className={classes.tableFixed}>
              <CollectionTableHead
                rows={rows}
                rowCount={items.length}
                type={'related'}
                classes={this.props.classes}
              />

              <SortableTable
                items={items}
                onSortEnd={this.onSortEnd}
                useDragHandle={true}
                lockAxis="y"
                classes={classes}
                inlineButtonStyle={inlineButtonStyle}
                handleRemoveItem={this.props.handleRemoveItem}
              />
            </Table>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default connect(
  () => ({}),
  dispatch => bindActionCreators({ get, patch, freeze, unfreeze }, dispatch),
)(withRouter(withStyles(table)(PreviewsTable)))
