import {
  darken,
  lighten,
  fade,
} from '@material-ui/core/styles/colorManipulator'

const boxShadowMinimal =
  '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'

export const search = () => ({})

export const image = theme => ({
  small: {
    objectFit: 'contain',
    objectPosition: 'center',
    width: theme.spacing(4),
    height: 'auto',
    maxHeight: 30,
  },
  medium: {
    objectFit: 'contain',
    objectPosition: 'center',
    width: theme.spacing(6),
    height: 'auto',
    maxHeight: 30,
  },
  large: {
    objectFit: 'contain',
    objectPosition: 'center',
    width: '100%',
  },
})

export const table = theme => ({
  tableWrapper: {
    overflowX: 'auto',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  tableFixed: {
    tableLayout: 'fixed',
  },
  flexTableRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.common.white,
  },
  flexTableCell: {
    padding: '4px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: theme.spacing(5), // table cell will grow when the content is not wrapping
    flex: 1,
    alignItems: 'center',
    display: 'flex',
  },
  tableCell: {
    padding: '4px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: theme.spacing(5), // table cell will grow when the content is not wrapping
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  absolute: {
    position: 'absolute',
    width: 'calc(100% - 24px)',
  },
  spacer: {
    flex: '1 1 100%',
  },
  button: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  title: {
    flex: '0 0 auto',
  },
  toolBar: {
    height: theme.spacing(8),
    minHeight: theme.spacing(8),
  },
  formLabelTypography: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
})

export const alert = theme => ({
  default: {
    backgroundColor: theme.palette.action.default,
    boxShadow: boxShadowMinimal,
  },
  success: {
    backgroundColor: theme.palette.action.success,
    boxShadow: boxShadowMinimal,
  },
  error: {
    backgroundColor: theme.palette.action.error,
    boxShadow: boxShadowMinimal,
  },
  info: {
    backgroundColor: theme.palette.action.info,
    boxShadow: boxShadowMinimal,
  },
  warning: {
    backgroundColor: theme.palette.action.warning,
    boxShadow: boxShadowMinimal,
  },
  snackbar: {
    marginBottom: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  messages: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

const drawerWidth = 240

export const navigation = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: theme.palette.common.white,
  },
  paper: {
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.light,
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  typographyAppbarHeader: {
    flex: '1 0 auto',
    color: theme.palette.common.black,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.common.black,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  buttonDrawerClose: {
    color: theme.palette.common.white,
    position: 'relative',
    top: 12,
    left: 12,
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.black, 0.2),
    },
  },
  toolBar: {
    minHeight: theme.spacing(8),
    height: theme.spacing(8),
    paddingRight: theme.spacing(),
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(8),
    paddingRight: 0,
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentClose: {
    marginLeft: theme.spacing(9),
  },
  listItem: {
    paddingLeft: 22,
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.black, 0.2),
    },
  },
  listItemIcon: {
    color: theme.palette.primary.light,
    fontSize: 14,
  },
  listItemText: {
    color: theme.palette.primary.light,
    fontSize: 14,
  },

  // search
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: theme.spacing(27),
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  // TODO: following are for search, should be added there
  inputRoot: {
    color: 'inherit',
    width: '100%',
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(7),
    marginLeft: -8,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
})

export const form = theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  toolBar: {
    minHeight: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  toolBarSearch: {
    height: theme.spacing(3),
    minHeight: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  toolBarNested: {
    minHeight: theme.spacing(2),
    height: theme.spacing(2),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    padding: 0,
  },
  thumbnailSidebar: {
    marginTop: theme.spacing(3),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.primary,
    display: 'flex',
  },
  actionsFixed: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1201,
    position: 'fixed',
  },
  button: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
  },
  appBar: {
    marginBottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.light,
  },

  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  paperRight: {
    paddingLeft: 0,
    paddingRight: theme.spacing(3),
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tabsFlexContainer: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  tabRoot: {
    fontSize: theme.typography.fontSize,
    minWidth: theme.spacing(15),
    minHeight: 0,
    fontWeight: 400,
    textTransform: 'initial',
    color: theme.palette.common.black,
    '&$tabSelected': {
      color: theme.palette.primary.main,
    },
  },
  tabSelected: {},

  // expand: {
  //   transform: 'rotate(0deg)',
  //   transition: theme.transitions.create('transform', {
  //     duration: theme.transitions.duration.shortest,
  //   }),
  //   marginLeft: 'auto',
  //   [theme.breakpoints.up('sm')]: {
  //     marginRight: -8,
  //   },
  // },
  // expandOpen: {
  //   transform: 'rotate(180deg)',
  // },

  title: {
    flex: '0 0 auto',
  },
  card: {
    width: 300,
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  media: {
    objectFit: 'cover',
  },
  tableFixed: {
    tableLayout: 'fixed',
  },
  formControl: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formControlLabel: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    position: 'static',
    flexBasis: '20%',
    transform: 'none',
    paddingBottom: 6,
  },
  formLabelTypography: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
  formField: {
    width: '100%;',
  },
  input: {
    flex: 1,
    marginTop: '0 !important',
    padding: 0,
    justifyContent: 'flex-start',
  },
  inputCheckbox: {
    padding: 0,
    marginTop: '0 !important',
    justifyContent: 'flex-start',
    width: 'auto',
  },
  inputInput: {
    paddingTop: 2,
    paddingBottom: 10,
    color: 'inherit',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('border-bottom', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:focus': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  inputInputSearch: {
    color: 'inherit',
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('border-bottom', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:focus': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  selectRoot: {
    color: 'inherit',
    width: '100%',
    border: 'none',
  },
  selectSelect: {
    color: 'inherit',
    width: '100%',
    paddingRight: 0,
    paddingTop: 2,
    paddingBottom: 10,
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('border-bottom', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:focus': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
    },
  },
  selectSelectChecked: {
    color: 'inherit',
    width: '100%',
    paddingRight: 0,
    borderBottom: 0,
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  selectMenuList: {
    boxShadow: boxShadowMinimal,
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  selectMenuListItem: {
    padding: theme.spacing(),
  },
  selectMenuListItemSelected: {
    color: theme.palette.primary.contrastText,
    backgroundColor: `${theme.palette.primary.main} !important`, // https://stackoverflow.com/questions/50371315/how-to-override-material-ui-menuitem-selected-background-color
  },
  selectInput: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(0.5),
    marginLeft: 0,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
  },
  chipToggle: {
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(0.5),
    marginLeft: 0,
    border: `1px solid ${theme.palette.text.secondary}`,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
    '&.chipToggleSelected': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
  textarea: {
    width: '100%;',
  },
  wysiwygEditor: {
    marginBottom: theme.spacing(),
    minHeight: theme.spacing(8),
  },
  wysiwygContent: {
    flex: 1,
    padding: theme.spacing(),
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 32,
    paddingLeft: 0,
    cursor: 'pointer',
    '& *': {
      margin: '0 !important',
      padding: '0 !important',
    },
  },
  wysiwygButtonEdit: {
    position: 'absolute',
    right: 0,
  },

  // expansion panels
  expansionPanelRoot: {
    width: '100%',
    marginTop: '0!important',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  },
  expansionPanelExpanded: {
    margin: '0!important',
    borderBottom: 0,
  },
  expansionSummaryPanelRoot: {
    height: 40,
    minHeight: 40,
    paddingLeft: '0!important',
    paddingRight: '8px!important',
  },
  expansionPanelSummaryExpanded: {
    marginTop: '0!important',
    marginBottom: '0!important',
    minHeight: '0!important',
    // paddingRight: '0!important',
  },
  expansionPanelStacked: {
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    marginTop: 0,
    marginBottom: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expansionPanelSummary: {
    backgroundColor: theme.common.white,
    '&$expanded': {
      minHeight: 0,
      margin: 'auto',
      backgroundColor: theme.palette.divider,
    },
  },
  expanded: {
    minHeight: 0,
    margin: 'auto',
  },
  expansionPanelHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  expansionPanelHeaderItem: {
    flex: 1,
    flexBasis: '33.33333%',
  },
  expansionPanelDetails: {
    padding: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  default: {
    color: theme.common.white,
    backgroundColor: theme.palette.action.default,
    '&:hover': {
      backgroundColor: darken(theme.palette.action.default, 0.15),
    },
  },
  success: {
    color: theme.common.white,
    backgroundColor: theme.palette.action.success,
    '&:hover': {
      backgroundColor: darken(theme.palette.action.success, 0.15),
    },
  },
  error: {
    color: theme.common.white,
    backgroundColor: theme.palette.action.error,
    '&:hover': {
      backgroundColor: darken(theme.palette.action.error, 0.15),
    },
  },
  info: {
    color: theme.common.white,
    backgroundColor: theme.palette.action.info,
    '&:hover': {
      backgroundColor: darken(theme.palette.action.info, 0.15),
    },
  },
  warning: {
    color: theme.common.white,
    backgroundColor: theme.palette.action.warning,
    '&:hover': {
      backgroundColor: darken(theme.palette.action.warning, 0.15),
    },
  },

  loginContainer: {
    width: 300,
    height: '100vh',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  loginItem: {
    margin: 0,
    width: '100%',
  },
  loginFormControl: {
    width: '100%',
  },
  loginInputInput: {
    padding: theme.spacing(),
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  loginButton: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },

  buttonFab: {
    width: 30,
    height: 30,
    minWidth: 30,
    minHeight: 30,
  },
})
