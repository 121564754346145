import api from '../axios'
// import { CancelToken } from 'axios'

export const REQUEST_SUCCESS = 'api/REQUEST_SUCCESS'
export const REQUEST_ERROR = 'api/REQUEST_ERROR'

const initialState = {}

const handleError = (dispatch, error) => {
  if (error.response) {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Response Error')
      console.error(error)
    }
  } else if (error.request) {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Request Error')
      console.error(error)
    }
  } else {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Error')
      console.error(error)
    }
  }

  return dispatch({
    type: REQUEST_ERROR,
    ...error.response,
  })
}

export const getHeaders = ({ jwt }) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${jwt}`,
})

export const login = auth => dispatch => {
  let jwt
  return api
    .post('/auth/token', { auth })
    .then(resp => {
      ;({ jwt } = resp.data)
      const headers = getHeaders({ jwt })
      return api.get('/auth/', { headers })
    })
    .then(resp =>
      dispatch({
        type: REQUEST_SUCCESS,
        ...resp,
        data: { jwt, ...resp.data },
      }),
    )
    .catch(error => handleError(dispatch, error))
}

export const get = url => (dispatch, getState) => {
  // const source = CancelToken.source()
  const headers = getHeaders(getState().auth)
  return api
    .get(url, { headers /* , cancelToken: source.token */ })
    .then(resp =>
      dispatch({
        type: REQUEST_SUCCESS,
        ...resp,
        total: Number(resp.headers.total),
      }),
    )
    .catch(error => handleError(dispatch, error))
}

export const patch = (url, data = {}) => (dispatch, getState) => {
  const headers = getHeaders(getState().auth)
  return api({ method: 'PATCH', url, headers, data })
    .then(resp => dispatch({ type: REQUEST_SUCCESS, ...resp }))
    .catch(error => handleError(dispatch, error))
}

export const post = (url, data = {}) => (dispatch, getState) => {
  const headers = getHeaders(getState().auth)
  return api({ method: 'POST', url, headers, data })
    .then(resp => dispatch({ type: REQUEST_SUCCESS, ...resp }))
    .catch(error => handleError(dispatch, error))
}

export const del = url => (dispatch, getState) => {
  const headers = getHeaders(getState().auth)
  return api({ method: 'DELETE', url, headers })
    .then(resp => dispatch({ type: REQUEST_SUCCESS, ...resp }))
    .catch(error => handleError(dispatch, error))
}

export const validate = data => dispatch => {
  const headers = getHeaders(data)
  return api
    .get('/auth/', { headers })
    .then(resp => dispatch({ type: REQUEST_SUCCESS, ...resp }))
    .catch(error => handleError(dispatch, error))
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SUCCESS:
    case REQUEST_ERROR:
      return {
        ...state,
        ...action,
      }
    default:
      return state
  }
}
