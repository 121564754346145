import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem, RelatedEntries } from '..'
import { defaultDateInputValue } from '../../util'
import {
  FormFieldsSidebarCategories,
  FormFieldsSidebarThumbnail,
  FormFieldsSidebarProjects,
  FormFieldsSidebarMediaTabs,
  FormFieldsSidebarTags,
} from '../Form'

class Exhibition extends React.Component {
  static defaultProps = {
    item: {
      id: null,
      title: '',
      slug: '',
      start_date: defaultDateInputValue(),
      end_date: defaultDateInputValue(),
      location: '',
      categories: [],
      galleries: [],
      facilitator: '',
      exhibition_type: null,
      collaborators: '',
      tags: [],
      items: [],
    },
  }
  render() {
    return (
      <CollectionItem
        type="exhibition"
        sidebarRight={[
          <FormFieldsSidebarThumbnail key="thumbnail" />,
          <FormFieldsSidebarCategories key="categories" />,
          <FormFieldsSidebarProjects key="projects" />,
          <FormFieldsSidebarTags key="tags" />,
        ]}
        sidebarTabs={<FormFieldsSidebarMediaTabs />}
        sidebarBottom={<RelatedEntries type="work" />}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(Exhibition)
