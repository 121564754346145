import React from 'react'
import { FormFieldsMedia, FormMediaActions } from '.'
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia'
import { getCardMediaSource, getCardComponent } from '../../util'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

const CardMediaItem = props => (
  <CardMedia
    style={{
      height: props.height,
      objectFit: 'contain',
      cursor: props.onClick ? 'pointer' : 'default',
    }}
    title={props.item.title}
    alt={props.item.alt}
    onClick={props.onClick ? props.onClick : () => {}}
    className={props.classes.media}
    component={getCardComponent(props.item)}
    src={getCardMediaSource(props.item)}
    controls={getCardComponent(props.item) === 'audio'}
    preload="metadata"
  />
)

class FormMediaItemRow extends React.Component {
  state = { open: false }

  toggleDialog = () => this.setState({ open: !this.state.open })

  render() {
    const { item, classes } = this.props
    return (
      <Grid item sm={4}>
        <Grid container spacing={0} style={{ border: '1px solid gainsboro' }}>
          <Grid
            item
            sm={12}
            style={{ padding: 8, borderBottom: '1px solid gainsboro' }}
          >
            <CardMediaItem
              item={item}
              classes={classes}
              handleOpen={this.props.handleOpen}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.props.handleOpen(item)
              }}
              height={160}
            />
          </Grid>
          <Grid
            item
            sm={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignSelf: 'center',
              flexBasis: 'auto',
            }}
          >
            <FormMediaActions
              item={item}
              handleDownload={this.props.handleDownload}
              handleDeleteItem={this.props.handleDeleteItem}
              handleToggleVisibility={this.props.handleToggleVisibility}
              handleCopy={this.props.handleCopy}
              handleOpen={this.props.handleOpen}
              handleEdit={this.toggleDialog}
            />
          </Grid>
        </Grid>

        <Dialog
          maxWidth="md"
          open={this.state.open}
          onClose={this.toggleDialog}
          PaperProps={{
            style: {
              width: '100vw',
              height: '100vh',
              borderRadius: 0,
              overflow: 'auto',
            },
          }}
        >
          <DialogContent>
            <Grid container>
              <Grid item sm={12} style={{ marginBottom: 16 }}>
                <CardMediaItem
                  item={item}
                  classes={classes}
                  handleOpen={this.props.handleOpen}
                  height={320}
                />
              </Grid>
              <Grid item sm={12}>
                <FormFieldsMedia
                  fieldWidth={12}
                  item={item}
                  classes={classes}
                  type={this.props.type}
                  collection={this.props.collection}
                  context={this.props.context}
                  gallery={this.props.gallery}
                  schema={this.props.schema}
                  createInputData={this.props.createInputData}
                  processing={this.props.processing}
                  handleChange={this.props.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.toggleDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}

export default FormMediaItemRow
