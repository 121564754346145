import React from 'react'
import classNames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import { titleCase, getDefaultValue } from '../../util'

const FormInput = props =>
  props.type === 'checkbox' ? (
    <Checkbox
      disableRipple={true}
      checked={props.value}
      id={props.name}
      name={props.name}
      type={props.type}
      disabled={props.processing}
      className={props.classes.input}
      onChange={props.handleChange}
    />
  ) : (
    <Input
      id={props.name}
      name={props.name}
      type={props.type}
      disableUnderline
      style={{ width: '100%' }}
      classes={{ input: props.classes.inputInput }}
      disabled={props.processing}
      className={props.classes.input}
      value={props.value}
      onChange={props.handleChange}
    />
  )

class FormInputGroup extends React.Component {
  state = {
    value:
      this.props.terms[this.props.name] || getDefaultValue(this.props.type),
  }

  timer = null
  debounceSpeed = 400

  handleChange = e => {
    const { name } = this.props
    let { value } = e.currentTarget

    if (this.props.type === 'checkbox') {
      value = e.currentTarget.checked
    }

    this.setState({ value })

    window.clearTimeout(this.timer)
    this.timer = window.setTimeout(() => {
      this.props.handleChange({ currentTarget: { name, value } })
    }, this.debounceSpeed)
  }

  reset = () => {
    const { name } = this.props
    const value = getDefaultValue(this.props.type)
    this.props.handleChange({ currentTarget: { name, value } })
    this.setState({ value })
  }

  render() {
    const { type, classes } = this.props
    const { value } = this.state
    return (
      <FormControl className={classes.formControl}>
        <FormInput
          {...this.props}
          {...this.state}
          handleChange={this.handleChange}
        />
        {value !== getDefaultValue(type) && (
          <IconButton
            size="small"
            disableRipple
            style={{ padding: 8 }}
            onClick={this.reset}
          >
            <CancelIcon style={{ fontSize: 16 }} />
          </IconButton>
        )}
      </FormControl>
    )
  }
}

const MappingsSelect = props => {
  return (
    <React.Fragment>
      {Object.entries(props.mapping).map(([name]) => {
        return (
          <Chip
            key={name}
            label={titleCase(name)}
            onClick={props.toggleField(name)}
            className={classNames(props.classes.chipToggle, {
              chipToggleSelected: props.selectedFields[name],
            })}
          />
        )
      })}
    </React.Fragment>
  )
}

const SearchMapping = props => {
  return (
    <Grid item sm={12}>
      <Typography
        color="inherit"
        variant="subtitle1"
        style={{ margin: '-12px 0 12px' }}
      >
        Fields
      </Typography>

      <MappingsSelect
        mapping={props.mapping}
        classes={props.classes}
        selectedFields={props.selectedFields}
        toggleField={props.toggleField}
      />

      {Object.entries(props.mapping)
        .filter(([name]) => props.selectedFields[name] === true)
        .map(([name, data]) => {
          return (
            <Grid
              key={name}
              container
              spacing={0}
              style={{
                minHeight: 48,
                alignItems: 'center',
              }}
            >
              <Grid item sm={2}>
                <FormControl className={props.classes.formControl}>
                  <InputLabel
                    style={{
                      minHeight: 32,
                      display: 'flex',
                      alignItems: 'center',
                      flex: '1 0 100%',
                    }}
                    shrink={false}
                    disableAnimation={true}
                    className={props.classes.formControlLabel}
                  >
                    {titleCase(name)}
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item sm={2}>
                <FormControl className={props.classes.formControl}>
                  <Select
                    style={{
                      marginTop: 0,
                      flex: 1,
                      marginLeft: 8,
                      marginRight: 8,
                    }}
                    disableUnderline
                    className={props.classes.input}
                    disabled={props.processing}
                    value={data.value}
                    onChange={e => props.handleOperatorChange(e, name)}
                    classes={{
                      root: props.classes.selectRoot,
                      select: props.classes.selectSelect,
                    }}
                    inputProps={{
                      id: `mapping-${name}`,
                      name: `mapping-${name}`,
                    }}
                    MenuProps={{
                      classes: {
                        paper: props.classes.selectMenuList,
                      },
                    }}
                  >
                    {props.getOperators(data.type).map(operator => (
                      <MenuItem key={operator} value={operator}>
                        {operator}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={8}>
                <FormInputGroup
                  name={name}
                  type={data.type}
                  terms={props.terms}
                  classes={props.classes}
                  processing={props.processing}
                  handleChange={props.handleChange}
                />
              </Grid>
            </Grid>
          )
        })}
    </Grid>
  )
}

export default SearchMapping
