import React from 'react'
import * as R from 'ramda'
import find from 'lodash/find'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import uniq from 'lodash/uniq'
import { DirectUpload } from 'activestorage'
import download from 'downloadjs'
import arrayMove from 'array-move'

import {
  castType,
  getCollectionType,
  ensureDecodedURI,
  getCollectionNameFromMimeType,
  pluralize,
  lensById,
  extractErrorMessages,
} from '../../util'

import { ImageModal } from '../../components'
import { mediaTypes } from '../../constants'

const collectionItemWrapper = WrappedComponent => {
  class CollectionItemHoC extends React.Component {
    state = {
      processing: false,
      item: {
        categories: [],
        projects: [],
        galleries: [],
        tags: [],
        exhibitions: [],
        impressions: [],
        previews: [],
        previewables: [],
        items: [],
      },
      categories: [],
      projects: [],
      tags: [],
      exhibitions: [],
      impressions: [],
      previews: [],
      imageModalOnSubmit: () => {},
      imageModalOnCanel: () => {},
      imageModalVisible: false,
      downloadImages: [],
    }

    constructor(props) {
      super(props)

      this.getItem = this.getItem.bind(this)
      this.getCategories = this.getCategories.bind(this)
      this.getProjects = this.getProjects.bind(this)
      this.getExhibitions = this.getExhibitions.bind(this)
      this.handleFileChange = this.handleFileChange.bind(this)
      this.handleThumbnailChange = this.handleThumbnailChange.bind(this)
      this.handleThumbnailDelete = this.handleThumbnailDelete.bind(this)
      this.handleUpdate = this.handleUpdate.bind(this)
      this.handleDeleteItem = this.handleDeleteItem.bind(this)
      this.handleToggleVisibility = this.handleToggleVisibility.bind(this)
      this.handleAddChip = this.handleAddChip.bind(this)
      this.handleDeleteChip = this.handleDeleteChip.bind(this)
      this.handleRemoveItem = this.handleRemoveItem.bind(this)
      this.createExport = this.createExport.bind(this)
      this.getImageIds = this.getImageIds.bind(this)
      this.getImages = this.getImages.bind(this)
      this.toggleDownloadImage = this.toggleDownloadImage.bind(this)
      this.getSelectedDownloadImage = this.getSelectedDownloadImage.bind(this)
      this.resetDownloadImages = this.resetDownloadImages.bind(this)
      this.moveItems = this.moveItems.bind(this)
    }

    getItem = () => {
      const { id } = this.props.match.params
      const type = getCollectionType(this.props)

      if (id !== 'new') {
        return this.props.get(`/${pluralize(type)}/${id}`).then(({ data }) =>
          this.setState(state => ({
            processing: false,
            item: { ...this.props.item, ...state.item, ...data },
          })),
        )
      }

      return Promise.resolve() // TODO
    }

    getCategories = () =>
      this.props.get('/categories?per_page=100').then(({ data }) =>
        this.setState(() => ({
          categories: data,
        })),
      )

    getProjects = () =>
      this.props.get('/projects?per_page=100').then(({ data }) =>
        this.setState(() => ({
          projects: data,
        })),
      )

    getExhibitions = () =>
      this.props.get('/exhibitions?per_page=100').then(({ data }) =>
        this.setState(() => ({
          exhibitions: data,
        })),
      )

    prepareNestedAttributes = item => {
      item.work_ids = []
      item.exhibition_ids = []
      item.project_ids = []
      item.publication_ids = []
      item.previewables = []

      if (item.categories) item.category_ids = item.categories.map(a => a.id)
      if (item.projects) item.project_ids = item.projects.map(a => a.id)
      if (item.exhibitions) {
        item.exhibition_ids = item.exhibitions.map(a => a.id)
      }
      if (item.documents) item.document_ids = item.documents.map(a => a.id)
      if (item.tags) item.tag_list = item.tags.map(a => a.name).join() // as string
      if (item.impressions) item.impressions_attributes = [...item.impressions]

      if (item.items && item.items.length) {
        const projects = filter(item.items, { type: 'project' })
        const exhibitions = filter(item.items, { type: 'exhibition' })
        const works = filter(item.items, { type: 'work' })
        const publications = filter(item.items, { type: 'publication' })

        if (projects && projects.length) {
          item.project_ids = [...item.project_ids, ...projects.map(a => a.id)]
        }

        if (exhibitions && exhibitions.length) {
          item.exhibition_ids = [
            ...item.exhibition_ids,
            ...exhibitions.map(a => a.id),
          ]
        }

        if (works && works.length) {
          item.work_ids = [...item.work_ids, ...works.map(a => a.id)]
        }

        if (publications && publications.length) {
          item.publication_ids = [
            ...item.publication_ids,
            ...publications.map(a => a.id),
          ]
        }

        item.previewables = item.items.reduce((acc, curr) => {
          if (!curr.position) return acc
          const { previewable_join_id: id, position } = curr
          return acc.concat({
            id,
            item_id: curr.id,
            position,
          })
        }, [])
      }

      return item
    }

    prepareStrongParams = item => {
      const item_ = { ...item }

      const blacklist = [
        'thumbnail', // added dynamically
        'galleries', // updated separately
        'impressions', // updated via impressions_attributes
        'tags', // added as tag_list
      ]

      for (let i = 0; i < blacklist; i++) {
        delete item_[blacklist[i]]
      }

      return item_
    }

    handleCreate = e => {
      e.preventDefault()
      this.props.freeze()

      const { item } = this.state
      const type = getCollectionType(this.props)

      let item_ = { ...item }
      item_ = this.prepareNestedAttributes(item_)
      item_ = this.prepareStrongParams(item_)

      this.setState({ processing: true }, () => {
        this.props.post(`/${pluralize(type)}`, { [type]: item_ }).then(resp => {
          const { data } = resp

          let message
          let variant

          if (resp.status >= 400) {
            variant = 'error'
            message = extractErrorMessages(data)
          } else {
            variant = 'success'
            message = 'Entry was created successfully'
            this.props.history.push(`/${type}/${data.id}`)
            this.getItem()
          }

          this.props.unfreeze()
          this.props.show({ variant, message })
        })
      })
    }

    handleUpdate = e => {
      if (e) e.preventDefault()
      this.props.freeze()

      const { item } = this.state
      const { id } = item
      const { galleries } = item
      const type = getCollectionType(this.props)

      let item_ = { ...item }
      item_ = this.prepareNestedAttributes(item_)
      item_ = this.prepareStrongParams(item_)

      const promises = [
        this.props.patch(`/${pluralize(type)}/${id}`, { [type]: item_ }),
      ]

      galleries.forEach(c => {
        mediaTypes.forEach(k => {
          if (!c[k].length) return
          promises.push(
            c[k].map(attachment =>
              this.props.patch(
                `/${pluralize(type)}/${id}/galleries/${c.id}/${k}/${
                  attachment.id
                }`,
                { attachment },
              ),
            ),
          )
        })
      })

      this.setState({ processing: true }, () => {
        Promise.all(promises).then(resps => {
          this.setState({ processing: false })
          this.props.unfreeze()

          let error = false
          let variant = 'success'
          let message = 'Entry was updated successfully'

          resps.forEach(resp => {
            const { data } = resp
            if (resp.status >= 400) {
              error = true
              variant = 'error'
              message = extractErrorMessages(data)
            }
          })

          if (error) {
            this.props.show({ variant, message })
          } else {
            this.props.show({
              variant,
              message,
            })
          }
        })
      })
    }

    handleChange = (e, gallery) => {
      const { schema } = this.props
      const { id, name, value, collection } = e.currentTarget || e.proxy
      // TODO: should be nailed down better, gets schema type based on collection or url
      const type =
        (collection && pluralize.singular(collection)) ||
        getCollectionType(this.props)

      let lens

      if (e.proxy && collection && !gallery) {
        // for updating categories etc
        lens = R.compose(
          R.lensProp('item'),
          R.lensProp(collection),
          lensById(id),
          R.lensProp(name),
        )
      } else if (e.proxy && collection && gallery) {
        // update galleries
        lens = R.compose(
          R.lensProp('item'),
          R.lensProp('galleries'),
          lensById(gallery.id),
          R.lensProp(collection),
          lensById(id),
          R.lensProp(name),
        )
      } else {
        lens = R.lensPath(['item', name])
      }

      let value_ = value

      if (
        Object.keys(schema).length &&
        schema[type] &&
        schema[type][name] &&
        schema[type][name].fieldType
      ) {
        // console.log('Validating with schema')
        const { fieldType } = schema[type][name]
        value_ = castType(value, fieldType)
      } else {
        console.warn('Not validating with schema')
      }

      this.setState(R.set(lens, value_))
    }

    handleNestedItemChange = (e, key) => {
      const { id, name } = e.proxy
      let { value } = e.proxy

      const lens = R.compose(
        R.lensProp('item'),
        R.lensProp(key),
        lensById(id),
        R.lensProp(name),
      )

      if (!value) value = !R.view(lens, this.state)
      this.setState(R.set(lens, value))
    }

    uploadFile = (file, galleryID) => {
      const postType = getCollectionType(this.props)
      const { type } = file
      const { id } = this.props.match.params
      const { jwt } = this.props.auth
      const url = process.env.REACT_APP_DIRECT_UPLOAD_URL
      const fileType = pluralize(getCollectionNameFromMimeType(type))

      let variant = 'error'
      let message = 'An unexpected error occurred'

      const upload = new DirectUpload(file, url, {
        directUploadWillCreateBlobWithXHR: xhr => {
          xhr.setRequestHeader('Authorization', `Bearer ${jwt}`)
        },
      })

      return upload.create((err, blob) => {
        if (err) {
          variant = 'error'
          message = err.message
          this.props.show({ variant, message })
          return
        }

        const { signed_id } = blob

        // prettier-ignore
        this.props
          .post(`/${pluralize(postType)}/${id}/galleries/${galleryID}/${fileType}`, { attachment: { signed_id, mime_type: type } })
          .then(resp => {
            const { data } = resp

            variant = 'success'
            message = 'Item was uploaded successfully'

            if (resp.status >= 400) {
              variant = 'error'
              message = extractErrorMessages(data)
              this.props.show({ variant, message })
              return
            }

            const gallery = find(this.state.item.galleries, { id: Number(galleryID) })
            const galleryIndex = findIndex(this.state.item.galleries, { id: Number(galleryID) })
            const { galleries } = this.state.item

            gallery[fileType].unshift(data)
            galleries.splice(galleryIndex, 1, gallery)

            this.setState({
              ...this.state,
              item: {
                ...this.state.item,
              },
            }, () => this.props.show({ variant, message }))
          })
      })
    }

    uploadFiles = (files, galleryID) => {
      const promises = []
      Array.prototype.slice
        .call(files, 0)
        .map(a => promises.push(this.uploadFile(a, galleryID)))
      return Promise.all(promises).catch(console.error)
    }

    handleFileChange = e =>
      this.uploadFiles(e.currentTarget.files, e.currentTarget.name)

    handleThumbnailChange = e => {
      this.props.freeze()

      const file = e.currentTarget.files[0]
      const postType = getCollectionType(this.props)
      const { id } = this.props.match.params
      const { jwt } = this.props.auth
      const url = process.env.REACT_APP_DIRECT_UPLOAD_URL

      let variant = 'error'
      let message = 'An unexpected error occurred'

      if (!file) {
        this.props.unfreeze()
        return console.warn('Could not find file')
      }

      const upload = new DirectUpload(file, url, {
        directUploadWillCreateBlobWithXHR: xhr => {
          xhr.setRequestHeader('Authorization', `Bearer ${jwt}`)
        },
      })

      return upload.create((err, blob) => {
        if (err) {
          variant = 'error'
          message = err.message
          this.props.show({ variant, message })
          return
        }

        const { signed_id } = blob

        // prettier-ignore
        this.props
          .patch(`/${pluralize(postType)}/${id}`, { [postType]: { thumbnail: signed_id }})
          .then(resp => {
            const { data } = resp

            variant = 'success'
            message = 'Item was uploaded successfully'

            if (resp.status >= 400) {
              variant = 'error'
              message = extractErrorMessages(data)
              this.props.unfreeze()
              this.props.show({ variant, message })
              return
            }

            this.props.unfreeze()
            this.setState(state => ({
              item: { ...state.item, thumbnail: data.thumbnail },
            }), () => this.props.show({ variant, message }))
          })
      })
    }

    handleThumbnailDelete = () => {
      const postType = getCollectionType(this.props)
      const { id } = this.props.match.params

      // prettier-ignore
      this.props
        .patch(`/${pluralize(postType)}/${id}`, { [postType]: { thumbnail_purge: true }})
        .then(resp => {
          const { data } = resp

          let variant = 'success'
          let message = 'Item was uploaded successfully'

          if (resp.status >= 400) {
            variant = 'error'
            message = extractErrorMessages(data)
            this.props.unfreeze()
            this.props.show({ variant, message })
            return
          }


          this.props.unfreeze()
          this.setState(state => ({
            item: { ...state.item, thumbnail: null },
          }), () => this.props.show({ variant, message }))
        }


        )
    }

    handleCheckboxChange = (e, data) => {
      const { name, checked, value } = data // item (i.e., category) passed as value

      if (data.collection) {
        this.setState(state => {
          const { item } = state
          const items = checked
            ? [...state.item[name]].filter(a => a.id !== value.id)
            : [...state.item[name], value]
          return {
            item: {
              ...item,
              [name]: items,
            },
          }
        })
      } else {
        this.setState({
          item: {
            ...this.state.item,
            [name]: e.target.checked,
          },
        })
      }
    }

    handleDelete = e => {
      e.preventDefault()
      // eslint-disable-next-line no-restricted-globals
      if (confirm('This action will permanently delete this entry.')) {
        const { item } = this.state
        const { id } = item
        const type = getCollectionType(this.props)

        this.props
          .del(`/${pluralize(type)}/${id}`, { [type]: item })
          .then(resp => {
            const { data } = resp

            let message
            let variant

            if (resp.status >= 400) {
              variant = 'error'
              message = extractErrorMessages(data)
            } else {
              variant = 'success'
              message = 'Entry was deleted successfully'
              this.props.history.push(`/${pluralize(type)}/`)
            }

            this.props.unfreeze()
            this.props.show({ variant, message })
          })
      }
    }

    // for nested items (media) in galleries
    handleDeleteItem = (child, galleryID) => {
      const postType = getCollectionType(this.props)
      const { item } = this.state
      const { id } = item
      const fileType = pluralize(getCollectionNameFromMimeType(child.mime_type))

      // prettier-ignore
      const url = `/${pluralize(postType)}/${id}/galleries/${galleryID}/${fileType}/${child.id}`

      this.props.del(url).then(resp => {
        const { data } = resp

        let variant = 'success'
        let message = 'Item was deleted successfully'

        if (resp.status >= 400) {
          variant = 'error'
          message = extractErrorMessages(data)
          this.props.show({ variant, message })
          return
        }

        const gallery = find(this.state.item.galleries, {
          id: Number(galleryID),
        })
        const galleryIndex = findIndex(this.state.item.galleries, {
          id: Number(galleryID),
        })
        const { id } = child
        const { galleries } = this.state.item

        gallery[fileType] = gallery[fileType].filter(a => a.id !== id)
        galleries.splice(galleryIndex, 1, gallery)

        this.setState(
          {
            ...this.state,
            item: {
              ...this.state.item,
            },
          },
          () => this.props.show({ variant, message }),
        )
      })
    }

    handleToggleVisibility = (attachment, galleryID) => {
      const postType = getCollectionType(this.props)
      const { item } = this.state
      const { id } = item
      const fileType = pluralize(
        getCollectionNameFromMimeType(attachment.mime_type),
      )

      // prettier-ignore
      const url = `/${pluralize(postType)}/${id}/galleries/${galleryID}/${fileType}/${attachment.id}`
      const nextPublic = !attachment.public
      const nextAttachment = { ...attachment, public: nextPublic }

      this.props.patch(url, { attachment: nextAttachment }).then(resp => {
        const { data } = resp

        let variant = 'success'
        let message = 'Item was updated successfully'

        if (resp.status >= 400) {
          variant = 'error'
          message = extractErrorMessages(data)
          this.props.show({ variant, message })
          return
        }

        const lens = R.compose(
          R.lensProp('item'),
          R.lensProp('galleries'),
          lensById(galleryID),
          R.lensProp(fileType),
          lensById(attachment.id),
          R.lensProp('public'),
        )

        const nextState = R.set(lens, nextPublic)
        this.setState(nextState, () => this.props.show({ variant, message }))
      })
    }

    handleDeleteChip = (e, item) => {
      e.preventDefault()
      const { id, name } = item
      const lens = R.lensPath(['item', name])
      const items = R.filter(a => a.id !== id, R.view(lens, this.state))
      this.setState(R.set(lens, items))
    }

    handleAddChip = (e, name, data) => {
      e.preventDefault()
      const lens = R.lensPath(['item', name])
      const items = R.append(data, R.view(lens, this.state))
      this.setState(R.set(lens, items))
    }

    handleRemoveItem = (e, item) => {
      e.preventDefault()
      e.stopPropagation()
      this.props.freeze()

      const { id, type } = item
      const postId = this.state.item.id
      const postType = getCollectionType(this.props)
      const lens = R.lensPath(['item', 'items'])
      const items = R.filter(
        a => (a.id === id && a.type === type ? false : true),
        R.view(lens, this.state),
      )

      let item_ = { ...item, items: [...items] }
      item_ = this.prepareNestedAttributes(item_)
      item_ = this.prepareStrongParams(item_)

      this.setState({ processing: true }, () => {
        this.props
          .patch(`/${pluralize(postType)}/${postId}`, { [postType]: item_ })
          .then(resp => {
            const { data } = resp

            let message
            let variant

            if (resp.status >= 400) {
              variant = 'error'
              message = extractErrorMessages(data)
            } else {
              variant = 'success'
              message = 'Entry was deleted successfully'
            }

            this.props.unfreeze()
            this.setState(R.set(lens, items), () =>
              this.props.show({ variant, message }),
            )
          })
      })
    }

    handleCopy = () =>
      this.props.show({ variant: 'success', message: 'Copied to clipboard.' })

    handleOpen = item => {
      this.props.open(item)
    }

    handleDownload = item => {
      const { urls, title, mime_type } = item
      const url = urls.large || urls.source
      const extension = url.slice(url.lastIndexOf('.'))
      const filename = title
        ? `${ensureDecodedURI(title)}${extension}`
        : ensureDecodedURI(url.slice(url.lastIndexOf('/') + 1))

      const req = new XMLHttpRequest()

      req.open('GET', url, true)
      req.responseType = 'blob'
      req.onload = () => download(req.response, filename, mime_type)
      req.send()
    }

    handlePDFDownload = data => {
      const { url, file_name, mime_type } = data
      const req = new XMLHttpRequest()

      req.open('GET', url, true)
      req.responseType = 'blob'
      req.onload = () => download(req.response, file_name, mime_type)
      req.send()
    }

    resetDownloadImages = () => this.setState(() => ({ downloadImages: [] }))

    getSelectedDownloadImage = ({ id }) =>
      this.state.downloadImages.includes(id)

    getImages = () => {
      const { item } = this.state
      if (!item || !item.galleries || !item.galleries.length) return []

      return uniq(
        this.state.item.galleries.reduce(
          (acc, curr) => acc.concat(curr.images),
          [],
        ),
      )
    }

    getImageIds = () => {
      const { item } = this.state
      if (!item || !item.galleries || !item.galleries.length) return []

      return uniq(
        this.state.item.galleries.reduce(
          (acc, curr) => acc.concat(curr.images.map(({ id }) => id)),
          [],
        ),
      )
    }

    toggleDownloadImage = ({ id }) => {
      const { downloadImages } = this.state
      let nextDownloadImages
      if (this.getSelectedDownloadImage({ id })) {
        nextDownloadImages = downloadImages.filter(imageID => imageID !== id)
      } else {
        nextDownloadImages = [...downloadImages, id]
      }

      this.setState(() => ({ downloadImages: nextDownloadImages }))
    }

    hideImageModal = () => this.setState(() => ({ imageModalVisible: false }))

    showImageModal = (onSubmit, onCancel) => {
      this.setState(() => ({
        imageModalOnSubmit: onSubmit,
        imageModalOnCanel: onCancel,
        imageModalVisible: true,
      }))
    }

    createExport = (/* items */) => {
      this.showImageModal(
        () => {
          const type = getCollectionType(this.props)
          const { id } = this.state.item
          const { downloadImages: images } = this.state

          if (!images) return

          const documents = [{ id, type, images }]

          this.props.freeze()

          this.props
            .post('/export', { documents })
            .then(({ data }) => this.handlePDFDownload(data))
            .then(() => {
              this.props.unfreeze()
              this.hideImageModal()
            })
        },
        () => {
          this.hideImageModal()
        },
      )
    }

    moveItems = (oldIndex, newIndex) => {
      const items = [...this.state.item.items]
      const nextItems = arrayMove(items, oldIndex, newIndex).map((item, i) => ({
        ...item,
        position: i + 1,
      }))

      const lens = R.lensPath(['item', 'items'])
      this.setState(R.set(lens, nextItems))
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            getItem={this.getItem}
            getCategories={this.getCategories}
            getProjects={this.getProjects}
            getExhibitions={this.getExhibitions}
            handleCreate={this.handleCreate}
            handleUpdate={this.handleUpdate}
            handleChange={this.handleChange}
            handleNestedItemChange={this.handleNestedItemChange}
            handleFileChange={this.handleFileChange}
            handleCheckboxChange={this.handleCheckboxChange}
            handleThumbnailChange={this.handleThumbnailChange}
            handleThumbnailDelete={this.handleThumbnailDelete}
            handleDelete={this.handleDelete}
            handleDeleteChip={this.handleDeleteChip}
            handleRemoveItem={this.handleRemoveItem}
            handleAddChip={this.handleAddChip}
            handleDeleteItem={this.handleDeleteItem}
            handleToggleVisibility={this.handleToggleVisibility}
            handleCopy={this.handleCopy}
            moveItems={this.moveItems}
            handleOpen={this.handleOpen}
            handleDownload={this.handleDownload}
            item={Object.assign({}, this.props.item, this.state.item)}
            schema={this.props.schema}
            categories={this.state.categories}
            tags={this.state.tags}
            projects={this.state.projects}
            processing={this.state.processing}
            exhibitions={this.state.exhibitions}
            impressions={this.state.impressions}
            previews={this.state.previews}
            createExport={this.createExport}
          />
          <ImageModal
            getImages={this.getImages}
            getSelectedDownloadImage={this.getSelectedDownloadImage}
            toggleDownloadImage={this.toggleDownloadImage}
            resetDownloadImages={this.resetDownloadImages}
            imageModalOnSubmit={this.state.imageModalOnSubmit}
            imageModalOnCanel={this.state.imageModalOnCanel}
            imageModalVisible={this.state.imageModalVisible}
          />
        </React.Fragment>
      )
    }
  }

  return CollectionItemHoC
}

export default collectionItemWrapper
