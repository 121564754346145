import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import { Thumbnail } from '.'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
  },
  gridListTile: {
    width: '33%!important',
    padding: `${theme.spacing(2)}px!important`,
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background: 'rgba(255,255,255,0.6)',
  },
})

const ImageModal = props => {
  const images = props.getImages()
  const { classes } = props
  return (
    <Dialog
      maxWidth="md"
      open={props.imageModalVisible}
      onClose={props.resetDownloadImages}
      PaperProps={{
        style: {
          width: '100vw',
          height: '100vh',
          borderRadius: 0,
          overflow: 'auto',
        },
      }}
    >
      <DialogTitle>Select Images to be Exported</DialogTitle>
      <DialogContent>
        <GridList>
          {images.map(image => {
            const selected = props.getSelectedDownloadImage(image)
            return (
              <GridListTile
                key={`image-${image.id}`}
                classes={{
                  root: classes.gridListTile,
                }}
              >
                <Thumbnail size="large" thumbnail={image} />
                <GridListTileBar
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                  actionIcon={
                    <Fab
                      size="small"
                      disableRipple
                      onClick={() => props.toggleDownloadImage(image)}
                    >
                      {selected ? <CloseIcon /> : <AddIcon />}
                    </Fab>
                  }
                />
              </GridListTile>
            )
          })}
        </GridList>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.imageModalOnCanel} color="default">
          Cancel
        </Button>
        <Button onClick={props.imageModalOnSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ImageModal)
