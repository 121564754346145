import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import { form } from '../styles'

class SearchActions extends React.Component {
  state = { fixed: true }
  maxHeight = 0
  // maxHeight = 64

  componentDidMount() {
    this.bindHandlers()
  }

  componentWillUnmount() {
    this.unBindHandlers()
  }

  handleScroll = () => {
    const { fixed } = this.state
    const _fixed = window.scrollY >= this.maxHeight

    if (fixed !== _fixed) {
      this.setState({ fixed: _fixed })
    }
  }

  bindHandlers = () => {
    window.addEventListener('scroll', this.handleScroll)
  }
  unBindHandlers = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const { classes } = this.props
    const { fixed } = this.state
    return (
      <div
        className={classNames(classes.actions, {
          [classes.actionsFixed]: fixed,
        })}
      >
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(form)(SearchActions)
