import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem } from '..'

class User extends React.Component {
  static defaultProps = {
    item: {
      id: null,
      name: '',
      email: '',
      role: 'guest',
      password: '',
      password_confirmation: '',
    },
  }
  render() {
    return <CollectionItem type="user" {...this.props} />
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(User)
