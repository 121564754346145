export const AUTH_SAVE = 'auth/AUTH_SAVE'
export const AUTH_LOAD = 'auth/AUTH_LOAD'
export const AUTH_CLEAR = 'auth/AUTH_CLEAR'
export const AUTH_LOGIN = 'auth/AUTH_LOGIN'
export const AUTH_VERIFY = 'auth/AUTH_VERIFY'
export const AUTH_EXPIRE = 'auth/AUTH_EXPIRE'

export const LOCAL_STORAGE_KEY = 'cd_auth'

const initialState = {
  id: null,
  email: '',
  jwt: '',
  role: 'guest',
  groups: [],
  expires: null,
  error: {},
}

const setLocalStorageData = data => {
  const { id, email, role, groups, jwt, expires } = data
  let auth = { id, email, role, groups, jwt }
  if (expires) auth.expires = expires
  auth = JSON.stringify(auth)
  localStorage.setItem(LOCAL_STORAGE_KEY, auth)
}
const getLocalStorageData = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')

const setExpires = (hours = 24) => Date.now() + hours * 60 * 60 * 1000

export const load = () => dispatch => {
  const data = getLocalStorageData()
  return Promise.resolve(dispatch({ type: AUTH_LOAD, ...data }))
}

export const verify = () => (dispatch, getState) => {
  const now = Date.now()
  const { expires } = getLocalStorageData()
  if (!expires || expires < now) {
    return Promise.resolve(dispatch({ type: AUTH_EXPIRE }))
  }

  setLocalStorageData({ ...getState().auth })
  return Promise.resolve(dispatch({ type: AUTH_VERIFY }))
}

export const save = data => dispatch => {
  const expires = setExpires()
  const auth = { ...data, expires }

  setLocalStorageData(auth)
  return Promise.resolve(dispatch({ type: AUTH_SAVE, ...auth }))
}

export const clear = () => dispatch => {
  localStorage.removeItem(LOCAL_STORAGE_KEY)
  return Promise.resolve(dispatch({ type: AUTH_CLEAR }))
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SAVE:
    case AUTH_LOAD:
    case AUTH_VERIFY:
    case AUTH_LOGIN:
      return {
        ...state,
        ...action,
      }
    case AUTH_EXPIRE:
    case AUTH_CLEAR:
      return { ...initialState }

    default:
      return state
  }
}
