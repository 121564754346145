import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import intersection from 'lodash/intersection'
import findIndex from 'lodash/findIndex'
import pick from 'lodash/pick'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Toolbar from '@material-ui/core/Toolbar'
import TablePagination from '@material-ui/core/TablePagination'

import { freeze, unfreeze } from '../../reducers/spinner'
import { get, post } from '../../reducers/api'
import { update, clear } from '../../reducers/search'
import { ensureDecodedURIComponent, parameterize } from '../../util'
import {
  SearchResults,
  SearchModels,
  SearchAdvanced,
  SearchActions,
  SearchFilters,
  SearchTablePaginationActions,
} from '..'
import { form } from '../../styles'

const DEFAULT_FROM = 0
const DEFAULT_SIZE = 25
const DEFAULT_MODEL = 'work'

const filterSchema = new Map([
  [
    'categories',
    {
      key: 'category_ids',
      name: 'categories',
    },
  ],
])

class Search extends React.Component {
  state = {
    from: DEFAULT_FROM,
    size: DEFAULT_SIZE,
    page: 1,
    query: {
      bool: {
        must: [],
        must_not: [],
        should: [],
        range: [],
      },
    },
    simpleQueryString: '',
    advanced: false,
    models: [],
    mapping: {},
    filters: [],
    context: 'intersection',
    selectedFields: {},
    mappings: {},
    filterMappings: {},
    terms: {},
    operators: {},
    processing: false,
  }

  reset = () => {
    this.setState({
      from: DEFAULT_FROM,
      size: DEFAULT_SIZE,
      page: 1,
      query: {},
      models: [],
      mapping: {},
      filters: [],
      context: 'intersection',
      terms: {},
      operators: {},
      simpleQueryString: '',
      advanced: false,
    })
  }

  componentDidMount() {
    this.initialize()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.search()
    }
  }

  initialize = async () => {
    const filterMappings = await this.getFilterMappings()
    const mappings = {}
    let selectedFields = {}

    // mappings are the chips that can be used to query fiels (production_date,
    // title, etc). Filter out unnecessary mappings here to clean up the UI

    const mappingFilter = [
      'artist_proofs',
      'collaborators',
      'description',
      'notes',
      'medium',
      'production_date',
      'production_status',
      'sku',
      'title',
      'credits',
      'location_gallery',
      'location_other',
      'location_storage',
      'sales_status',
      'signed',
      'end_date',
      'exhibition_type',
      'location',
      'start_date',
      'author_primary',
      'author_secondary',
      'copies_created',
      'copies_stored',
      'copyright',
      'edition',
      'editor',
      'language',
      'publication_type',
      'publisher',
      'translator',
      'uuid',
      'uuid_type',
      'year',
    ]

    Object.entries(this.props.mappings).forEach(([key, _val]) => {
      // change 'date' type to 'integer' so that dates can be queried by year only
      const val = pick(_val, mappingFilter)
      Object.entries(val).forEach(([k, v]) => {
        if (v === 'date') {
          val[k] = 'number'
          return
        }
        val[k] = v
      })

      mappings[key] = val

      const keys = Object.keys(val)

      selectedFields = {
        ...selectedFields,
        ...R.zipObj(keys, R.repeat(false, keys.length)),
      }
    })

    this.setState({ mappings, filterMappings, selectedFields }, () => {
      if (!window.location.search) {
        // default values for advanced search
        selectedFields.title = true
        return
      }

      this.search()
    })
  }

  search = () => {
    const { context, selectedFields } = this.state

    const search = new window.URLSearchParams(
      ensureDecodedURIComponent(window.location.search),
    )

    const from = search.has('from') ? search.get('from') : DEFAULT_FROM
    const size = search.has('size') ? search.get('size') : DEFAULT_SIZE

    const models = search.has('models[]')
      ? search.getAll('models[]').filter(Boolean)
      : []

    const mapping = this.getMapping({ context, models })

    const filters = search.has('filters[]')
      ? search
          .getAll('filters[]')
          .filter(Boolean)
          .map(filter => JSON.parse(filter))
      : []

    if (search.has('s')) {
      const simpleQueryString = search.get('s')
      this.setState(
        {
          simpleQueryString,
          models,
          mapping,
          filters,
          from,
          size,
        },
        this.getSimpleResults,
      )
    }

    if (search.has('q')) {
      let query
      query = JSON.parse(decodeURIComponent(search.get('q')))
      query = this.buildQuery(query)

      const { terms, operators } = query

      Object.keys(terms).forEach(key => (selectedFields[key] = true))
      Object.entries(operators).forEach(
        ([key, value]) => (mapping[key].value = value),
      )

      this.setState(
        {
          terms,
          operators,
          advanced: true,
          models,
          mapping,
          filters,
          selectedFields,
          from,
          size,
        },
        this.getAdvancedResults,
      )
    }
  }

  buildQuery = queryObject => {
    const verbs = ['must', 'must_not', 'should']
    let { terms: nextTerms, operators: nextOperators } = this.state

    if (!queryObject || !queryObject.bool) {
      return { terms: nextTerms, operators: nextOperators }
    }

    verbs.forEach(verb => {
      if (queryObject.bool[verb] && queryObject.bool[verb].length) {
        queryObject.bool[verb].forEach(({ match, range }) => {
          let name, value, rangeData, key, operator

          if (match) {
            ;[name, value] = R.head(Object.entries(match))
            operator = this.getOperator(verb)
          } else if (range) {
            ;[name, rangeData] = R.head(Object.entries(range))

            // setting the selects on page load. searching by only year means
            // that gte is to the original query (2019) and that 'lt' had been
            // added dynamically (2020)

            if (rangeData.lt && rangeData.gte) {
              key = 'eq'
            } else if (rangeData.lte) {
              key = 'lte'
            } else {
              key = 'gte'
            }

            value = key === 'eq' ? rangeData.gte : rangeData[key]

            operator = this.getOperator(key)
          }

          nextTerms = R.set(R.lensPath([name]), value, nextTerms)
          nextOperators = R.set(R.lensPath([name]), operator, nextOperators)
        })
      }
    })

    return { terms: nextTerms, operators: nextOperators }
  }

  getFilterMappings = () => {
    const promises = []
    const filterMappings = {}

    filterSchema.forEach(filter =>
      promises.push(
        this.props.get(`/${filter.name}`).then(({ data }) => {
          filterMappings[filter.name] = new Map()
          data.forEach(({ id, name }) =>
            filterMappings[filter.name].set(parameterize(name), {
              id,
              name,
              key: filter.key,
            }),
          )
        }),
      ),
    )

    return Promise.all(promises).then(() => filterMappings)
  }

  getOperator = operator => {
    return operator === 'must'
      ? 'and'
      : operator === 'must_not'
      ? 'not'
      : operator === 'should'
      ? 'or'
      : operator
  }

  getVerb = operator => {
    if (
      operator === 'and' ||
      operator === 'eq' ||
      operator === 'lte' ||
      operator === 'gte'
    ) {
      return 'must'
    }

    if (operator === 'not') {
      return 'must_not'
    }

    return 'should'
  }

  getMatch = (operator, key, val) => {
    // for eq, constrain range to both let and gte since ES (correctly) doesn't
    // support 'eq' in ranges
    if (operator === 'eq') {
      return {
        range: {
          [key]: {
            lt: String(parseInt(val, 10) + 1),
            gte: val,
          },
        },
      }
    }

    if (operator === 'lte' || operator === 'gte') {
      return {
        range: {
          [key]: {
            [operator]: val,
          },
        },
      }
    }

    return {
      match: {
        [key]: val,
      },
    }
  }

  getQuery = () => {
    const { terms, operators } = this.state
    let query = { bool: {} }

    // https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html
    Object.entries(terms).forEach(([key, _val]) => {
      const val = typeof _val === 'boolean' ? _val : _val.trim()
      if (!val) return

      const operator = operators[key]
      const verb = this.getVerb(operator)
      const lens = R.lensPath(['bool', verb])

      if (R.isNil(R.view(lens, query))) query = R.set(lens, [], query)

      const match = this.getMatch(operator, key, val)
      query = R.over(lens, R.append(match), query)
    })

    return query
  }

  getModelsParam = () =>
    this.state.models.length
      ? this.state.models.map(model => `models[]=${model}`).join('&')
      : 'models[]='

  getFiltersParam = () =>
    this.state.filters.length
      ? this.state.filters
          .map(filter => `filters[]=${JSON.stringify(filter)}`)
          .join('&')
      : 'filters[]='

  pushSimpleResults = () => {
    const { simpleQueryString, from, size } = this.state
    const search = new window.URLSearchParams(
      ensureDecodedURIComponent(window.location.search),
    )
    const modelsParam = this.getModelsParam()
    const filtersParam = this.getFiltersParam()
    const method = search.has('s') || search.has('q') ? 'push' : 'replace'

    this.props.history[method]({
      search: ensureDecodedURIComponent(
        `?s=${simpleQueryString}&${modelsParam}&${filtersParam}&from=${from}&size=${size}`,
      ),
    })
  }

  getSimpleResults = () => {
    const { simpleQueryString: query, models, filters, from, size } = this.state

    return this.props
      .post('/search', { query, models, filters, from, size })
      .then(data =>
        this.props.update({ results: data.data, total: data.total }),
      )
  }

  pushAdvancedResults = () => {
    const query = this.getQuery()
    const modelsParam = this.getModelsParam()
    const filtersParam = this.getFiltersParam()
    const { from, size } = this.state

    console.log('query', JSON.stringify(query, null, 2))

    this.props.history.push({
      search: ensureDecodedURIComponent(
        `?q=${JSON.stringify(
          query,
        )}&${modelsParam}&${filtersParam}&from=${from}&size=${size}`,
      ),
    })
  }

  getAdvancedResults = () => {
    const query = this.getQuery()
    const { models, filters, from, size } = this.state

    return this.props
      .post('/search', { query, models, filters, from, size })
      .then(data =>
        this.props.update({ results: data.data, total: data.total }),
      )
  }

  getResults = e => {
    if (e) e.preventDefault()

    return this.state.advanced
      ? this.pushAdvancedResults()
      : this.pushSimpleResults()
  }

  handleClick = (e, item) => {
    e.preventDefault()
    this.props.history.push(`/${item.type}/${item.id}`)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ from: DEFAULT_FROM }, this.getResults)
  }

  // passing a string instead of an object as query causes rails to perform
  // matching based on ES simple_query_string
  handleSimpleQueryStringChange = e =>
    this.setState({ simpleQueryString: e.currentTarget.value })

  setContext = e => {
    const { value } = e.target
    const mapping = this.getMapping({ context: value })
    this.setState({ context: value, mapping })
  }

  handleOperatorChange = (e, key) => {
    const { operators, mapping } = this.state
    const { value } = e.target

    const nextOperators = R.set(R.lensPath([key]), value, operators)
    const nextMapping = R.set(R.lensPath([key, 'value']), value, mapping)

    this.setState({ operators: nextOperators, mapping: nextMapping })
  }

  handleInputChange = e => {
    const { name, value } = e.currentTarget
    const { terms } = this.state
    const nextTerms = R.set(R.lensPath([name]), value, terms)

    this.setState({ terms: nextTerms })
  }

  handleModelChange = e => {
    const { value, checked } = e.target
    const { models, mappings } = this.state

    if (checked) {
      if (!mappings[value]) {
        R.set(R.lensPath([value]), this.props.mappings[value], mappings)
      }
      models.push(value)
    } else {
      models.splice(models.indexOf(value), 1)
    }

    const mapping = this.getMapping({ models })
    this.setState({ mapping, models })
  }

  handleFilterChange = collection => id => e => {
    const { name, checked } = e.currentTarget
    const { filterMappings, filters } = this.state
    const { key } = filterMappings[collection].get(name)
    const filter = { [key]: id }

    if (checked) {
      filters.push(filter)
    } else {
      filters.splice(findIndex(filters, filter), 1)
    }

    this.setState({ filters })
  }

  getDefaultOperatorValue = inputType => {
    return R.head(this.getOperators(inputType))
  }

  getOperators = val => {
    switch (val) {
      case 'date':
      case 'number':
        return ['eq', 'lte', 'gte']

      default:
        return ['and', 'or', 'not']
    }
  }

  getMapping = ({ mappings, models, context } = {}) => {
    let mapping = {}

    if (!models) {
      ;({ models } = this.state)
    }
    if (!mappings) {
      ;({ mappings } = this.state)
    }
    if (!context) {
      ;({ context } = this.state)
    }

    if (!models.length) return mapping

    if (context === 'intersection') {
      // get intersection of selected models
      const keys = intersection(
        ...models.filter(Boolean).map(key => Object.keys(mappings[key])),
      )

      // since all selected models contain the required keys, we can create
      // mappings from the values in the first model in the collection
      const types = mappings[R.head(models)]

      keys.forEach(
        key =>
          (mapping[key] = {
            type: types[key],
            value: this.getDefaultOperatorValue(types[key]),
          }),
      )
    }
    if (context === 'union') {
      models.forEach(key => (mapping = { ...mapping, ...mappings[key] }))
    }

    return mapping
  }

  toggleAdvanced = () => {
    const { advanced, models, selectedFields } = this.state
    if (!models.length) models.push(DEFAULT_MODEL)
    if (!Object.values(selectedFields).filter(Boolean).length) {
      // default value
      selectedFields.title = true
    }

    const mapping = this.getMapping({ models })
    this.setState({ advanced: !advanced, models, mapping })
  }

  toggleField = name => () => {
    const { selectedFields } = this.state
    let {
      operators: nextOperators,
      mapping: nextMapping,
      terms: nextTerms,
    } = this.state
    const defaultValue = this.getDefaultOperatorValue(nextMapping[name].type)
    const nextSelectedFields = { ...selectedFields }

    nextSelectedFields[name] = !nextSelectedFields[name]

    if (nextSelectedFields[name]) {
      // Adds operator
      nextOperators = R.set(R.lensPath([name]), defaultValue, nextOperators)
      nextMapping = R.set(
        R.lensPath([name, 'value']),
        defaultValue,
        nextMapping,
      )
    } else {
      // Removes operator
      nextOperators = R.dissocPath([name], nextOperators)
      nextTerms = R.dissocPath([name], nextTerms)
    }

    this.setState({
      selectedFields: nextSelectedFields,
      operators: nextOperators,
      mapping: nextMapping,
      terms: nextTerms,
    })
  }

  handleFirstPageButtonClick = () => {
    this.setState({ from: 0 }, this.getResults)
  }

  handleBackButtonClick = () => {
    const { from, size } = this.state
    const from_ = Number(from)
    const size_ = Number(size)
    const nextFrom = from_ - size_ >= 0 ? from_ - size_ : 0
    this.setState({ from: nextFrom }, this.getResults)
  }

  handleNextButtonClick = () => {
    const { from, size } = this.state
    const from_ = Number(from)
    const size_ = Number(size)
    const nextFrom = from_ + size_
    this.setState({ from: nextFrom }, this.getResults)
  }

  handleLastPageButtonClick = () => {
    const { size } = this.state
    const { total } = this.props.search
    const size_ = Number(size)
    const nextFrom = total - size_ < 0 ? 0 : total - size_
    this.setState({ from: nextFrom }, this.getResults)
  }

  handlePageChange = (e, position) => {
    switch (position) {
      case 'first':
        return this.handleFirstPageButtonClick()
      case 'prev':
        return this.handleBackButtonClick()
      case 'next':
        return this.handleNextButtonClick()
      case 'last':
        return this.handleLastPageButtonClick()
      default:
        break
    }
  }

  handleRowsPerPageChange = e => {
    const rowsPerPage = Number(e.target.value)
    this.setState({ size: rowsPerPage }, this.getResults)
  }

  render() {
    const { classes } = this.props
    const { results, total } = this.props.search
    const {
      context,
      processing,
      simpleQueryString,
      models,
      terms,
      mapping,
      mappings,
      selectedFields,
      filters,
      filterMappings,
      advanced,
      from,
      size,
    } = this.state

    const total_ = Number(total)
    const from_ = Number(from)
    const size_ = Number(size)
    const page = Math.ceil(from_ / size_)

    return (
      <React.Fragment>
        <Toolbar className={classes.toolBarSearch}>
          <SearchActions
            classes={classes}
            advanced={advanced}
            reset={this.reset}
            toggleAdvanced={this.toggleAdvanced}
            handleSubmit={this.handleSubmit}
          />
        </Toolbar>

        <Paper className={classes.paper}>
          <Grid container>
            <form
              onSubmit={this.handleSubmit}
              style={{
                width: '100%',
                marginBottom: 12,
                display: advanced ? 'none' : 'block',
              }}
            >
              <FormControl className={classes.formControl}>
                <Input
                  id="simple"
                  type="text"
                  disableUnderline
                  placeholder="Search"
                  style={{ width: '100%' }}
                  classes={{ input: classes.inputInputSearch }}
                  disabled={processing}
                  value={simpleQueryString}
                  onChange={this.handleSimpleQueryStringChange}
                />
              </FormControl>
              <button hidden={true} type="submit" />
            </form>

            <Grid item sm={12}>
              <SearchAdvanced
                advanced={advanced}
                context={context}
                processing={processing}
                models={models}
                terms={terms}
                mapping={mapping}
                selectedFields={selectedFields}
                classes={classes}
                toggleField={this.toggleField}
                setContext={this.setContext}
                handleOperatorChange={this.handleOperatorChange}
                onChange={this.handleSimpleQueryStringChange}
                getOperators={this.getOperators}
                handleChange={this.handleInputChange}
              />
            </Grid>

            <Grid container>
              <SearchModels
                models={models}
                mappings={mappings}
                processing={processing}
                onChange={this.handleModelChange}
              />

              <SearchFilters
                filters={filters}
                advanced={advanced}
                processing={processing}
                filterMappings={filterMappings}
                onChange={this.handleFilterChange}
              />
            </Grid>
          </Grid>

          <SearchResults
            order={this.state.order}
            orderBy={this.state.orderBy}
            results={results}
            handleClick={this.handleClick}
          />

          {results && results.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[]} // disable
              component="div"
              count={total_}
              rowsPerPage={size_}
              page={page}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleRowsPerPageChange}
              ActionsComponent={SearchTablePaginationActions}
            />
          ) : (
            <Typography variant="body1">No results were found.</Typography>
          )}
        </Paper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ search, mappings, schema }) => ({
  search,
  mappings,
  schema,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      get,
      post,
      update,
      clear,
      freeze,
      unfreeze,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(form)(Search))
