import React from 'react'
import { connect } from 'react-redux'
import { roles } from '../constants'

class Permit extends React.Component {
  /*
    Binary	Becomes
    100	    4
    101	    5
    110	    6
    111	    7
  */

  /*
    su
      7
      - c r u d
    ad
      7
      - c r u d
    ed
      6
      - c r u
    gu
      4
      - r
  */

  getPermissions = () => {
    const { permissions } = this.props
    const [u, g, o] = String(permissions)
      .split('')
      .map(a => Number(a).toString(2))
    return [u, g, o]
  }

  getRole = () => {
    const { role } = this.props.auth
    return roles[role]
  }

  getAccess = () => {
    const permissions = this.getPermissions()
    const role = this.getRole()
    const [r, w, x] = permissions[role].split('').map(a => Number(a))
    return { r, w, x }
  }

  viewable = () => {
    return this.getAccess().r
  }

  editable = () => {
    return this.getAccess().w
  }

  executable = () => {
    return this.getAccess().x
  }

  render() {
    // console.log('access', this.getAccess())
    return (
      <React.Fragment>
        {this.editable() && this.props.editable
          ? React.cloneElement(this.props.editable)
          : null}
        {this.viewable() && !this.editable() && this.props.viewable
          ? React.cloneElement(this.props.viewable)
          : null}
        {this.executable() && this.props.executable
          ? React.cloneElement(this.props.executable)
          : null}
        {// defaults to editable if children present
        !this.props.editable &&
        !this.props.viewable &&
        !this.props.executable &&
        this.editable() &&
        this.props.children
          ? React.cloneElement(this.props.children)
          : null}
      </React.Fragment>
    )
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  () => ({}),
)(Permit)
