import React from 'react'
import { connect } from 'react-redux'
import { CollectionItem } from '..'
import {
  FormFieldsSidebarExhibitions,
  FormFieldsSidebarImpressions,
  FormFieldsSidebarCategories,
  FormFieldsSidebarThumbnail,
  FormFieldsSidebarProjects,
  FormFieldsSidebarTags,
  FormFieldsSidebarMediaTabs,
} from '../Form'

// Basically a copy of Category class
class Edition extends React.Component {
  static defaultProps = {
    item: {
      id: null,
      title: '',
      slug: '',
      collaborators: '',
      description: '',
      notes: '',
      created_date: null,
      destroyed_date: null,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      production_status: 'produced',
      production_date: null,
      production_cost: 0,
      medium: '',
      sku: '',
      proofs: 0,
      artist_proofs: 0,
      tags: '',
      project_ids: [],
      exhibition_ids: [],
      impressions: [],
    },
  }

  render() {
    return (
      <CollectionItem
        type="edition"
        sidebarRight={[
          <FormFieldsSidebarThumbnail key="thumbnail" />,
          <FormFieldsSidebarProjects key="projects" />,
          <FormFieldsSidebarExhibitions key="exhibitions" />,
          <FormFieldsSidebarCategories key="categories" />,
          <FormFieldsSidebarTags key="tags" />,
        ]}
        sidebarTabs={<FormFieldsSidebarMediaTabs />}
        sidebarBottom={[<FormFieldsSidebarImpressions key="impressions" />]}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ schema }) => ({ schema }),
  () => ({}),
)(Edition)
