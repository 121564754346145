import React from 'react'
import uniq from 'lodash/uniq'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const previewable = WrappedComponent => {
  class Previewable extends React.Component {
    state = {
      open: false,
      previews: [],
    }

    componentDidMount() {
      this.getPreviews()
    }

    getPreviews = () =>
      this.props
        .get('/previews?per_page=100')
        .then(({ data }) => this.setState({ previews: data }))

    togglePreviewDialog = () => {
      this.setState({ open: !this.state.open })
    }

    addToPreview = preview => () => {
      const { id, items } = preview
      const { selected } = this.props

      let nextPreview = {
        work_ids: [],
        exhibition_ids: [],
        publication_ids: [],
        edition_ids: [],
        project_ids: [],
      }

      // Organize existing preview.items by type
      items.forEach(item => {
        const key = `${item.type}_ids`
        nextPreview[key].push(item.id)
      })

      // Organize selected items by type
      selected.forEach(item => {
        const key = `${item.type}_ids`
        nextPreview[key].push(item.id)
      })

      // Make sure there are only unique values
      nextPreview = Object.keys(nextPreview).reduce((acc, key) => {
        acc[key] = uniq(nextPreview[key])
        return acc
      }, {})

      this.props.patch(`/previews/${id}`, { preview: nextPreview }).then(() => {
        this.props.clearSelected()
        this.togglePreviewDialog()
        this.getPreviews()
      })
    }

    render() {
      const { open, previews } = this.state
      return (
        <React.Fragment>
          <WrappedComponent
            previews={previews}
            togglePreviewDialog={this.togglePreviewDialog}
            {...this.props}
          />
          <Dialog
            maxWidth="sm"
            open={open}
            onClose={this.toggleDialog}
            PaperProps={{
              style: {
                width: '100vw',
                height: '100vh',
                borderRadius: 0,
                overflow: 'auto',
              },
            }}
          >
            <DialogContent>
              <Grid container>
                <Grid item sm={12}>
                  {previews.map((preview, i) => (
                    <List key={i} dense={true}>
                      <ListItem>
                        <ListItemText
                          primary={preview.name}
                          secondary={`${preview.count} Entries`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton onClick={this.addToPreview(preview)}>
                            <AddCircleIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  ))}
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.togglePreviewDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )
    }
  }

  return Previewable
}

export default previewable
