import React from 'react'
import { Permit } from '../'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { form } from '../../styles'

const FormCheckbox = props => {
  const { classes } = props
  const checked = Boolean(props.item.value)

  // console.log(props.item, props.item.value)

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        shrink={false}
        disableAnimation={true}
        className={classes.formControlLabel}
        htmlFor={props.item.name}
      >
        {props.item.label}
      </InputLabel>
      <Permit
        permissions={764}
        editable={
          <Checkbox
            name={props.item.name}
            disableRipple={true}
            className={props.classes.inputCheckbox}
            classes={{
              root: props.classes.root,
              checked: props.classes.checked,
            }}
            checked={checked}
            onChange={e => props.handleCheckboxChange(e, props.item)}
            readOnly={props.item.readonly}
            required={props.item.required}
          />
        }
        viewable={
          <Checkbox
            readOnly={true}
            disableRipple={true}
            className={props.classes.inputCheckbox}
            required={props.item.required}
            checked={props.item.checked}
          />
        }
      />
    </FormControl>
  )
}

export default withStyles(form)(FormCheckbox)
