import React from 'react'
import find from 'lodash/find'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import { titleCase, pluralize } from '../../util'
import { form } from '../../styles'

class FormFieldsCollectionSelect extends React.Component {
  handleChange = e => {
    const name = pluralize(this.props.collection)
    const { value } = e.target
    const { options } = this.props
    const values = value.map(id => find(options, { id }))

    this.props.handleChange({ proxy: { name, value: values } })
  }

  getLabel = id => {
    const { options } = this.props
    return find(options, { id }).label
  }

  getChecked = () => {
    const { options } = this.props
    return options.filter(a => a.checked).map(a => a.id)
  }

  render() {
    const { classes, options } = this.props
    const collection = pluralize(this.props.collection)
    const checked = this.getChecked()

    return (
      <React.Fragment>
        <Toolbar className={classes.toolBarNested}>
          <Typography className={classes.formLabelTypography} noWrap>
            {titleCase(collection)}
          </Typography>
        </Toolbar>

        <FormControl className={classes.formControl}>
          <Select
            multiple
            disableUnderline
            classes={{
              root: classes.selectRoot,
              select: checked.length
                ? classes.selectSelectChecked
                : classes.selectSelect,
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
              classes: {
                paper: classes.selectMenuList,
              },
            }}
            value={checked}
            onChange={this.handleChange}
            input={<Input className={classes.selectInput} />}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(id => (
                  <Chip
                    key={id}
                    label={this.getLabel(id)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
          >
            {options.map(item => (
              <MenuItem
                disableRipple
                key={item.id}
                value={item.id}
                classes={{
                  root: classes.selectMenuListItem,
                  selected: classes.selectMenuListItemSelected,
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>
    )
  }
}

export default withStyles(form)(FormFieldsCollectionSelect)
