const initialState = {
  work: {},
  exhibition: {},
  publication: {},
  edition: {},
  project: {},
  category: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
